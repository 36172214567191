import { connect, ConnectedProps } from "react-redux";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { FormikProps, Form, withFormik } from "formik";
import * as Yup from "yup";
import { MessageBox } from "../../helpers";
import { userActions } from "../../../actions/login.action";
import { RootState } from "../../../app/store";
import { ArrowIcon } from "../../../features/icons/Icons";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { Link } from "react-router-dom";

const mapState = (state: RootState) => {
  const { _processing, _errors } = state.authentication;
  return { _processing, _errors };
};

const actionCreators = {
  login: userActions.login,
};

const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface PurchaseQuantityFormValues {
  quantity: number;
}

interface OtherProps extends PropsFromRedux {
  message?: string;
}

function InnerPurchaseQuantityForm(
  props: OtherProps & FormikProps<PurchaseQuantityFormValues>
) {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    _errors,
    _processing,
  } = props;

  return (
    <FingerPrintView
      viewLabel="Lapin Leadership Fingerprint™ Assessment"
      largeTitle="Purchase Assessments"
      background="lightgrey"
      purchaseStep="quantity"
    >
      <Form noValidate id="purchase_quantity" className="purchase-quantity">
        <h3>{props.message}</h3>

        {_errors && <MessageBox variant="danger" messages={_errors} />}

        <FormGroup>
          <FormLabel>Assessment Purchase Quantity</FormLabel>
          <span className="field-description">
            How many assessments would you like for this license?
          </span>
          <FormControl
            type="number"
            name="quantity"
            placeholder="Enter quantity…"
            className="quantity"
            //   onChange={handleChange}
            //   onBlur={handleBlur}
            value={values.quantity}
          />
          {touched.quantity && errors.quantity && (
            <div className="error-message">{errors.quantity}</div>
          )}
        </FormGroup>

        {_processing && <div>Loading</div>}
        <Row className="form-message above-button">
          <p>1-10: $0.00 / per assessment</p>
          <p>20+: $0.00 / per assessment</p>
        </Row>
        <Row className="bottom-options">
          <Link to="/account">Back</Link>
          <div className="total">Total: $1,000</div>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Next
            <ArrowIcon />
          </Button>
        </Row>
      </Form>
    </FingerPrintView>
  );
}

interface PurchaseQuantityFormProps extends PropsFromRedux {
  quantity?: string;
  message?: string;
}

const PurchaseQuantityForm = withFormik<
  PurchaseQuantityFormProps,
  PurchaseQuantityFormValues
>({
  // Transform outer props into form values
  // mapPropsToValues: (props) => {
  //   return {
  //     quantity: props.quantity || "admin@lapin.com", //TODO: remove this and set to blank
  //     firstName: props.firstName, //TODO: remove this and set to blank
  //     lastName: props.lastName,
  //   };
  // },

  // Add a custom validation function (this can be async too!)
  validationSchema: () => {
    const schema = Yup.object({
      quantity: Yup.string().required("quantity is a required field"),
    });

    return schema;
  },

  handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerPurchaseQuantityForm);

const connectedPurchaseQuantityPage = connector(PurchaseQuantityForm);
export { connectedPurchaseQuantityPage as PurchaseQuantityForm };
