import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import store from "../../app/store";
import "./progress-bar.scss";
//import { logout } from "../../services/user.service";

import { userActions } from "../../actions/login.action";
import { useValueData } from "../../app/hooks";

interface ProgressBarProps {
  currentPath: string;
}

export const pages: Array<string> = [
  "about-you",
  "your-professional-life",
  "a-few-questions",
  "earliest-memory",
  "your-parents",
  "meaningful-relationship",
  "best-boss",
  "conflict-moment",
  "video-value-drivers",
  "potential-values",
  "add-values",
  "core-value",
  "video-values-mapping",
  "mapping-question-1",
  "mapping-question-2",
  "test-your-value-drivers",
  "personal-choice",
  "personal-choice-values",
  "professional-choice",
  "professional-choice-values",
  "opportunity-conflict",
  "opportunity-conflict-values",
  "disappointment",
  "disappointment-values",
];

export const ProgressBar: React.FC<ProgressBarProps> = ({ currentPath }) => {
  interface number_map {
    [index: string]: number;
  }

  interface string_map {
    [index: string]: string;
  }

  const { selectorValues } = useValueData();

  const section1 = [
    "about-you",
    "your-professional-life",
    "a-few-questions",
    "earliest-memory",
    "your-parents",
    "meaningful-relationship",
    "best-boss",
    "conflict-moment",
  ];
  const section2 = [
    "video-value-drivers",
    "potential-values",
    "add-values",
    "core-value",
  ];

  const section3 = ["video-values-mapping"];

  const section4 = [
    "test-your-value-drivers",
    "personal-choice",
    "personal-choice-values",
    "professional-choice",
    "professional-choice-values",
    "opportunity-conflict",
    "opportunity-conflict-values",
    "disappointment",
    "disappointment-values",
  ];

  const totalProgress = pages.length;

  const [show, setShow] = useState(false);
  const [section, setSection] = useState(1);

  useEffect(() => {
    let show = false;
    if (currentPath.includes("digital-fingerprint")) {
      if (
        pages.includes(currentPath.split("/")[1]) ||
        currentPath.split("/")[1].startsWith("mapping-question")
      ) {
        show = true;
      }
    }
    setShow(show);
  }, [currentPath]);

  const DetermineWidth = () => {
    const initialWidth = (section - 1) / 4;
    let width = 0;
    if (show) {
      if (section != 3) {
        if (section === 1) {
          width =
            initialWidth +
            (section1.indexOf(currentPath.split("/")[1]) + 1) /
              section1.length /
              4;
        }
        if (section === 2) {
          width =
            initialWidth +
            (section2.indexOf(currentPath.split("/")[1]) + 1) /
              section2.length /
              4;
        }
        if (section === 4) {
          width =
            initialWidth +
            (section4.indexOf(currentPath.split("/")[1]) + 1) /
              section4.length /
              4;
        }
      } else if (section === 3) {
        if (currentPath.includes("mapping-question")) {
          width =
            initialWidth +
            parseInt(currentPath.split("/")[1].split("-")[2]) /
              Object.entries(selectorValues).length /
              4;
        } else {
          width = initialWidth;
        }
      }
    }

    return width * 100;
  };

  const DetermineSection = (page: string) => {
    let currentSection = 0;
    if (show) {
      if (section1.includes(page)) {
        currentSection = 1;
      }
      if (section2.includes(page)) {
        currentSection = 2;
      }
      if (page.startsWith("mapping-question") || section3.includes(page)) {
        currentSection = 3;
      }
      if (section4.includes(page)) {
        currentSection = 4;
      }
    }
    return currentSection;
  };

  useEffect(() => {
    if (currentPath.includes("digital-fingerprint") && show == true) {
      setSection(DetermineSection(currentPath.split("/")[1]));
    }
  }, [show, currentPath]);

  return (
    <React.Fragment>
      {show && (
        <div className="progress-bar-wrapper">
          <span className="progress-bar-step" id="message">
            {/* {`Section ${pages.indexOf(currentPath.split("/")[1]) + 1} of ${
              totalProgress + 1
            }`} */}
            {`Section ${section} of 4`}
          </span>
          <div className="progress-bar-outer">
            <div
              className="progress-bar-fill"
              style={{
                // width: `${
                //   (pages.indexOf(currentPath.split("/")[1]) / totalProgress) *
                //   100
                // }%`,
                width: show ? `${DetermineWidth()}%` : "0%",
              }}
            ></div>
          </div>
          <span id="logout" onClick={userActions.logout}>
            Logout
          </span>
        </div>
      )}
    </React.Fragment>
  );
};
