import React, { useEffect, useState } from "react";
import "./FingerprintForm.scss";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { TwoArrows, ArrowIcon } from "../../../features/icons/Icons";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { state, useErrorHandler, useFormData } from "../../../app/hooks";
import {
  IError,
  saveAboutYouData,
  saveProfessionalLifeDAta,
  saveUserFingerprintData,
  statusToVariant,
} from "../../../services/user.service";
import { useSelector } from "react-redux";
import type { RootState } from "../../../app/store";
import SessionService from "../../../services/session.service";
import {
  DeleteValueModal,
  MessageBox,
} from "../../helpers/message-box/message-box";
import { ROUTES } from "../../../app/routes";

function SingleOption(props: any) {
  const action = props.option;
  return <Dropdown.Item href={`#/${action}`}>{props.option}</Dropdown.Item>;
}

export function DropdownField(props: any) {
  const [selection, setSelection] = useState("");
  const [otherOption, setOtherOption] = useState(false);
  const name = props.name;
  const options = props.options;
  const optionsArray = Object.values(options);
  const optionsEnabled =
    options && options[0] !== "disabled" && Array.isArray(optionsArray);

  useEffect(() => {
    let initialSelection = "";
    if (props.formData !== null) {
      const { data } = props.formData;
      if (Object.keys(data).length !== 0) {
        if (
          data[props.name] !== null &&
          data[props.name] !== undefined &&
          options.includes(data[props.name])
        )
          initialSelection = data[props.name];
      }
    }
    setSelection(initialSelection);
  }, [props.formData, props.options]);

  const handleChange = (element: any) => {
    //const value = element.replace("-", " ").replace("#/", "");
    const value = element.replace("#/", "");
    if (props.other) {
      if (value === "Other") {
        setOtherOption(true);
      }
    }

    setSelection(value);

    if (props.UpdateValueScoresOnChange) {
      //props.UpdateValueScoresOnChange(props.formData.data[props.name], value);
    }
    if (props.onChange) {
      props.onChange(element);
    }
    props.editForm({ name: name, value: value });
  };
  let style = {};
  if (selection !== "") {
    style = { borderBottom: "solid 2px white" };
  }
  let placeholder: string = props.name;
  if (props.placeholder) {
    placeholder = props.placeholder;
  }

  useEffect(() => {
    if (props.other) {
      if (props.formData !== undefined) {
        if (
          Object.keys(props.formData.data).length > 0 &&
          props.formData.data["Industry"] !== null
        ) {
          if (props.formData?.data["Industry"]?.toLowerCase() === "other") {
            setOtherOption(true);
          }
        }
      }
    }
  }, [props.formData]);

  return (
    <div className={`dropdown-wrapper${otherOption ? " has-option" : ""}`}>
      <input type="hidden" />
      <Dropdown style={style} onSelect={handleChange}>
        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
          {selection !== "" ? selection : placeholder} <TwoArrows />
        </Dropdown.Toggle>
        {optionsEnabled && (
          <Dropdown.Menu>
            {optionsArray.map((option: any, index: number) => {
              return (
                <SingleOption
                  key={index}
                  option={option}
                  aria-label="disbaled"
                />
              );
            })}
          </Dropdown.Menu>
        )}
      </Dropdown>
      {otherOption && (
        <FingerprintTextInput
          name={props.name + "_other"}
          placeholder="Please specify"
          editForm={props.editForm}
          formData={props.formData}
        />
      )}
    </div>
  );
}

export function FingerprintTextInput(props: any) {
  const namer = props.name.replace(" ", "_");
  const classer = namer.replace("_", "-");

  const [text, setText] = useState<string>("");
  const handleChange = (e: any) => {
    setText(e.target.value);
    props.editForm({ name: props.name, value: e.target.value });
  };

  useEffect(() => {
    let initialTextValue: string = "";
    if (props.formData !== null && props.formData !== undefined) {
      if (props.formData.data[props.name] !== undefined) {
        initialTextValue = "" + props.formData.data[props.name];
      }
    }
    setText(initialTextValue);
  }, [props.formData]);

  return (
    <FormGroup>
      <FormLabel>{props.name}</FormLabel>
      <FormControl
        onChange={handleChange}
        type="text"
        name={namer}
        placeholder={`${props.placeholder}…`}
        className={classer}
        value={text}
        style={text !== "" ? { borderBottom: "solid 2px white" } : {}}
      />
    </FormGroup>
  );
}

export function FingerprintTextArea(props: any) {
  const [text, setText] = useState<string>("");

  const handleChange = (e: any) => {
    setText(e.target.value);
    const textChange = e.target.value;
    props.setEnoughText(text.length > 60 ? true : false);
    props.editForm({ name: props.name, value: textChange });
  };

  useEffect(() => {
    let initialTextValue: string = "";
    if (props.formData !== null) {
      if (Object.values(props.formData.data)[0] !== undefined) {
        initialTextValue = "" + Object.values(props.formData.data)[0];
        props.setEnoughText(initialTextValue.length > 60 ? true : false);
      }
    }
    setText(initialTextValue);
  }, [props.formData]);

  return (
    <div className="textarea-wrapper">
      <textarea
        onChange={handleChange}
        placeholder={props.placeholder ? props.placeholer : "Type here…"}
        value={text}
      ></textarea>
    </div>
  );
}

export function FormNavigation(props: any) {
  const { convertDataToString } = useFormData();
  const { updateHttpError } = useErrorHandler();

  const history = useHistory();
  const { pathname } = useLocation();

  const routeSelector = useSelector((state: RootState) => {
    return state.appRoute;
  });

  const formSelector = useSelector((state: RootState) => {
    return state.editForm;
  });

  const navClass = props.next && !props.back ? " next-only" : "";
  const backOrder = props.backOrder ? props.backOrder : "1";
  const leftClose = props.leftClose ? "left-close" : "";

  const save = async () => {
    let data = convertDataToString();

    if (data != "") {
      const result = await saveUserFingerprintData(
        data,
        SessionService.getInstance().licenseCode!,
        routeSelector.appRoute,
        routeSelector.visitedRoutes
      );
    }
  };

  const saveAboutYou = async () => {
    return await saveAboutYouData({
      gender: formSelector["about-you"]["Gender"],
      age: formSelector["about-you"]["Age"],
      country: formSelector["about-you"]["Country"],
      state: formSelector["about-you"]["State"],
      licenseCode: SessionService.getInstance().licenseCode!,
    });
  };

  const saveProfessionalLife = async () => {
    return await saveProfessionalLifeDAta({
      industry: formSelector["your-professional-life"]["Industry"],
      role: formSelector["your-professional-life"]["Role"],
      education: formSelector["your-professional-life"]["Education"],
      income: formSelector["your-professional-life"]["Income"],
      licenseCode: SessionService.getInstance().licenseCode!,
    });
  };

  const isFilledAboutYou =
    formSelector["about-you"] &&
    Object.keys(formSelector["about-you"])?.length >= 4;

  const isFilledProfessionalLife =
    formSelector["your-professional-life"] &&
    Object.keys(formSelector["your-professional-life"])?.length >= 4;

  return (
    <div
      className={`fingerprint-form-navigation${navClass} back-order-${backOrder} ${leftClose}`}
    >
      {props.back && (
        <Link to={`/digital-fingerprint/${props.back}`} className="back-button">
          {props.backText ? props.backText : "Back"}
        </Link>
      )}

      {props.navMessage && (
        <div className="nav-message">{props.navMessage}</div>
      )}

      <Button
        disabled={
          (pathname === ROUTES.aboutYou && !isFilledAboutYou) ||
          (pathname === ROUTES.professionalLife && !isFilledProfessionalLife) ||
          props.disabledNext
        }
        variant="primary white"
        onClick={() => {
          if (!routeSelector.appRoute.includes("video-value")) {
            save()
              .then(() => {
                if (pathname === ROUTES.aboutYou) {
                  if (formSelector["about-you"]) {
                    saveAboutYou()
                      .then((res) => {
                        history.push(`/digital-fingerprint/${props.next}`);
                      })
                      .catch((error) => {
                        updateHttpError({
                          messages: (error as IError).messages,
                          statusCode: (error as IError).statusCode,
                        });
                      });
                  }
                } else if (pathname === ROUTES.professionalLife) {
                  if (formSelector["your-professional-life"]) {
                    saveProfessionalLife()
                      .then(() => {
                        history.push(`/digital-fingerprint/${props.next}`);
                      })
                      .catch((error) => {
                        updateHttpError({
                          messages: (error as IError).messages,
                          statusCode: (error as IError).statusCode,
                        });
                      });
                  }
                }

                history.push(`/digital-fingerprint/${props.next}`);
              })
              .catch((error) => {
                updateHttpError({
                  messages: (error as IError).messages,
                  statusCode: (error as IError).statusCode,
                });
              });
          } else {
            history.push(`/digital-fingerprint/${props.next}`);
          }
        }}
      >
        {props.arrowDirection === "left" ? (
          <React.Fragment>
            <ArrowIcon color="#FFFFFF" direction="left" />
            {props.nextText ? props.nextText : "Next"}{" "}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {props.nextText ? props.nextText : "Next"}{" "}
            <ArrowIcon color="#FFFFFF" />
          </React.Fragment>
        )}
      </Button>
      {/* )} */}
    </div>
  );
}

export function RadioSelect(props: any) {
  const [deleteValue, setDeleteValue] = useState<string>("");
  const options = props.options;
  const optionsArray = Object.values(options);

  const optionsEnabled =
    options && options[0] !== "disabled" && Array.isArray(optionsArray);

  const handleSelect = (e: any) => {
    if (props.questionType !== null && props.questionType !== undefined) {
      if (props.questionType == "mapping") {
        if (e.target.value === "On further reflection, I do not value this.") {
          setDeleteValue(e.target.value);
        } else {
          props.editForm({ name: props.name, value: e.target.value });
        }
      }
    } else {
      props.editForm({ name: props.name, value: e.target.value });
    }
  };

  const removeMappingValue = () => {
    props.editForm({ name: props.name, value: deleteValue });
    setDeleteValue("");
  };

  return (
    <React.Fragment>
      {deleteValue !== "" && (
        <DeleteValueModal
          value={props.value}
          onClose={() => {
            setDeleteValue("");
          }}
          onDelete={removeMappingValue}
        />
      )}

      <div className="radio-wrapper">
        {optionsEnabled && (
          <>
            {optionsArray.map((option: any, index: number) => {
              return (
                <span
                  className={`radio-option ${
                    props.questionType ? props.questionType : ""
                  }`}
                  key={index}
                >
                  <input
                    onChange={handleSelect}
                    type="radio"
                    name={props.name}
                    value={option}
                    checked={option == props.formData.data[props.name]}
                  />
                  <label htmlFor="css">{option}</label>
                </span>
              );
            })}
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export function FingerprintForm(props: any) {
  const hasNav = props.next || props.back;
  const { httpError, updateHttpError } = useErrorHandler();

  const addErrorMessage = (message: string, statusCode: number) => {
    updateHttpError({
      messages: [message],
      statusCode: statusCode,
    });
  };

  return (
    <div className="fingerprint-form">
      {httpError.statusCode > 399 && (
        <MessageBox
          messages={httpError.messages}
          variant={statusToVariant(httpError.statusCode)}
        />
      )}

      <h2>{props.message}</h2>

      {props.explainer && <div className="explainer">{props.explainer}</div>}

      {props.children}

      {hasNav && (
        <FormNavigation
          next={props.next}
          nextText={props.nextText}
          back={props.back}
          navMessage={props.navMessage}
          backOrder={props.backOrder}
          backText={props.backText}
          leftClose={props.leftClose}
          arrowDirection={props.arrowDirection}
          enoughText={props.enoughText}
          addErrorMessage={addErrorMessage}
          name={props.name}
          disabledNext={props.disabledNext}
        />
      )}
      {/* </form> */}
    </div>
  );
}

export function ValidationValuesCheckList(props: any) {
  const checkInitialValues = (formData: state, name: string) => {
    if (formData.data[name]) {
      props.ValuesOnFirstRender(
        formData.route,
        formData.data[name].split(","),
        1
      );
    }
  };

  const convertToString = (array: Array<string>) => {
    let string = "";

    array.forEach((value, index) => {
      if (index != 0) {
        string = string + "," + value;
      } else {
        string = string + value;
      }
    });
    return string;
  };

  const DetermineClasses = (value: string): string => {
    let classes: string = "value ";
    if (props.formData.data[props.name]) {
      if (props.formData.data[props.name].split(",").includes(value)) {
        classes += "selected";
      }
    }
    return classes;
  };

  const handleChange = (e: any) => {
    let currentValues: Array<string> = [];
    if (props.formData.data[props.name]) {
      currentValues = props.formData.data[props.name].split(",");
    }

    if (currentValues.includes(e.target.value)) {
      currentValues.splice(currentValues.indexOf(e.target.value), 1);
      // props.ValidateValueScore(e.target.value, -1);
    } else {
      currentValues.push(e.target.value);
      //props.ValidateValueScore(e.target.value, 1);
    }

    props.editForm(
      { name: e.target.name, value: convertToString(currentValues) },
      e.target.type
    );
  };

  return (
    <fieldset>
      <ul className="values">
        {props.values.map((value: string, i: number) => {
          return (
            <li
              key={i}
              className={`value ${
                props.formData.data[props.name]
                  ? props.formData.data[props.name].split(",").includes(value)
                    ? "selected"
                    : " "
                  : false
              }`}
            >
              <input
                name={props.name}
                type="checkbox"
                value={value}
                onChange={handleChange}
                checked={
                  props.formData.data[props.name]
                    ? props.formData.data[props.name].split(",").includes(value)
                    : false
                }
              />
              <label>{value}</label>
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
}
