import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { allActions } from "./store";

import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getValuesLookUpTable, serverResponse } from "../services/user.service";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => {
  useDispatch<AppDispatch>();
};
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface dataValues {
  [key: string]: string;
}
export interface state {
  route: string;
  data: dataValues;
}

interface ValuesResponse {
  Phrase: string;
}

export const useFormData = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state: RootState) => {
    return state.editForm;
  });
  const location = useLocation();

  const checkInitialState = () => {
    let route = location.pathname.replace("/digital-fingerprint/", "");

    let initialState: state = { route, data: {} };
    if (route.includes("mapping-question")) {
      route = "mapping-question";
      if (selector[route] !== null && selector[route] !== undefined) {
        let tempObj: dataValues = {};
        for (const data in selector[route]) {
          tempObj[data] = selector[route][data];
        }
        initialState = { route, data: tempObj };
      } else {
        initialState = { route, data: {} };
      }
    } else {
      if (selector !== null) {
        if (selector[route] !== null && selector[route] !== undefined) {
          let tempObj: dataValues = {};
          for (const data in selector[route]) {
            tempObj[data] = selector[route][data];
          }
          initialState = { route, data: tempObj };
        }
      }
    }

    return initialState;
  };

  const convertDataToString = (): string => {
    return JSON.stringify(selector);
  };

  const getPotentialValues = () => {
    let potentialValues: string = "";
    let addedValues: string = "";
    if (selector !== null) {
      if (selector["potential-values"]) {
        potentialValues = selector["potential-values"]["potential-values"];
      }
      if (selector["add-values"]) {
        addedValues = selector["add-values"]["add-values"];
      }
      if (!selector["add-values"]) {
        return potentialValues;
      }
      if (addedValues == "" && potentialValues == "") {
        return "";
      }
    }

    return potentialValues + "," + addedValues;
  };

  const [formData, setFormData] = useState<state>({ route: "", data: {} });

  useEffect(() => {
    setFormData(checkInitialState());
  }, []);

  useEffect(() => {
    setFormData(checkInitialState());
  }, [selector]);

  useEffect(() => {
    if (location.pathname.includes("mapping-question")) {
      setFormData(checkInitialState());
    }
  }, [location]);

  const addToForm = (newData: dataValues, inputType: string = "") => {
    let currentFormData = { ...formData };
    if (formData.route.includes("mapping-question")) {
      currentFormData.route = "mapping-question";
    }
    currentFormData.data[newData.name] = newData.value;
    setFormData(currentFormData);
    if (Object.keys(formData.data).length !== 0) {
      dispatch(allActions.formActions.addDataHook(formData));
    }
  };

  const deleteMappingResponse = (value: string) => {
    dispatch(allActions.formActions.deleteMappingResponse(value));
  };

  return {
    formData,
    selector,
    setFormData,
    addToForm,
    getPotentialValues,
    convertDataToString,
    deleteMappingResponse,
  };
};

////                ////
////  Value Hook    ////
////                ////
////                ////
export const useValueData = () => {
  //let route = location.pathname.replace("/digital-fingerprint/", "");
  const dispatch = useDispatch();

  const selector = useSelector((state: RootState) => {
    return state.editForm;
  });
  const selectorValues = useSelector((state: RootState) => {
    return state.editValues;
  });
  const selectorPotentialValues = useSelector((state: RootState) => {
    return state.editPotentialValues;
  });

  const [finalValues, setFinalValues] = useState<Array<string>>([]);

  const CalculateValueScores = (values: string) => {
    const routes = [
      "mapping-question",
      "core-value",
      "personal-choice-values",
      "professional-choice-values",
      "opportunity-conflict-values",
      "disappointment-values",
    ];

    dispatch(allActions.valueActions.setSelectedValues(values.split(",")));
    //let newArray: Array<string> = [""];

    if (selector !== null) {
      routes.map((route, index) => {
        if (selector[route]) {
          if (route == "core-value") {
            dispatch(
              allActions.valueActions.addToValueAndRoute(
                route,
                selector[route][route],
                4
              )
            );
          } else if (route.includes("values")) {
            dispatch(
              allActions.valueActions.updateValuesOnFirstRender(
                route,
                selector[route][route].split(","),
                1
              )
            );
          } else if (route.includes("mapping-question")) {
            route = "mapping-question";

            Object.keys(selector[route]).map((value, key) => {
              if (values.split(",").includes(value)) {
                dispatch(
                  allActions.valueActions.updateMappingValuesOnFirstRender(
                    route,
                    value,
                    selector[route][value]
                  )
                );
              }
            });
          }
        }
      });
    }
  };

  const UpdateValueScore = (
    value: string,
    response: string,
    prevResponse: string
  ) => {
    dispatch(allActions.valueActions.addToValue(value, response, prevResponse));
  };

  const ValidateValueScore = (value: string, amount: number) => {
    dispatch(allActions.valueActions.addValidatingValue(value, amount));
  };

  const ValuesOnFirstRender = (
    route: string,
    values: Array<string>,
    amount: number
  ) => {
    dispatch(
      allActions.valueActions.updateValuesOnFirstRender(route, values, amount)
    );
  };

  const ValuesMappingOnFirstRender = (
    route: string,
    value: string,
    option: string
  ) => {
    dispatch(
      allActions.valueActions.updateMappingValuesOnFirstRender(
        route,
        value,
        option
      )
    );
  };

  const capitalizeString = (word: string) => {
    let returnString: string = "";

    let array = word.split(" ");
    for (let i = 0; i < array.length; i++) {
      if (array[i] !== "and" && array[i] !== "or") {
        array[i] = array[i].charAt(0).toUpperCase() + array[i].slice(1);
      }
    }
    if (array.length > 1) {
      returnString = array.join(" ");
    } else {
      returnString = array[0];
    }

    return returnString;
  };

  const getRandomValue = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  const createValues = (valuesFromDB: Array<string>) => {
    let string = "";
    const filterWords = ["that", "with", "very", "long", "what", "when"];
    let wordsToLookUp: Set<string> = new Set();
    const responses = [
      "earliest-memory",
      "your-parents",
      "meaningful-relationship",
      "best-boss",
      "conflict-moment",
    ];

    responses.forEach((value, index) => {
      if (selector[value] != null) {
        string = string + " " + Object.values(selector[value])[0];
      }
    });

    string = string.toLowerCase();

    const values = valuesFromDB
    .filter((phrase) => phrase != "")
    .map((phrase) => {
      return { 
        phrase: phrase, 
        count: string.match(new RegExp(`\\b${phrase}\\b`, 'gi'))?.length || 0 
      };
    }).filter((phrase) => phrase.count > 0)
    .sort((a, b) => b.count - a.count)
    .map((phrase) => phrase.phrase)
    .slice(0, 14);

    setFinalValues(values);
  };

  const fetchValues = async () => {
    let valuesArray: Array<string> = [];
    try {
      const values = await getValuesLookUpTable();
      values.forEach((value: ValuesResponse, index: number) => {
        valuesArray.push(value.Phrase);
      });
      createValues(valuesArray);
    } catch (error) {
      return error;
    }
  };

  const GetFromDB = async () => {
    return await fetchValues();
  };

  return {
    selector,
    finalValues,
    selectorValues,
    UpdateValueScore,
    ValidateValueScore,
    ValuesOnFirstRender,
    GetFromDB,
    ValuesMappingOnFirstRender,
    capitalizeString,
    CalculateValueScores,
    selectorPotentialValues,
  };
};

// const errorContext = createContext(
//   {
//     globalError: {messages: [], statusCode: 0},
//     setGlobalError: () => {}
//   }
// )

export const useErrorHandler = () => {
  // const {globalError, setGlobalError } = useContext(errorContext);
  const dispatch = useDispatch();
  const selectorError = useSelector((state: RootState) => {
    return state.editHttpError;
  });

  const [httpError, setHttpError] = useState<serverResponse>({
    messages: [],
    statusCode: 0,
  });

  const updateHttpError = (response: serverResponse, seconds: number = 6) => {
    dispatch(
      allActions.errorActions.updateHttpError(
        response.messages,
        response.statusCode
      )
    );
    setTimeout(() => {
      dispatch(allActions.errorActions.updateHttpError([""], 0));
    }, seconds * 1000);
  };

  useEffect(() => {
    if (selectorError.statusCode === 401) {
      dispatch(allActions.userActions.logout());
    }
  }, [selectorError]);

  return { httpError, selectorError, updateHttpError };
};
