import "./FingerPrintView.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Logo } from "../../logo/Logo";
import { FC, ReactNode, useContext } from "react";
import { ProgressBar } from "../../../components/ProgressBar/ProgressBar";
import { FingerprintContext } from "../../../screens/FingerprintExperience/Fingerprint.context";
import { QUESTIONS_AMOUNT_BASIC } from "../../../screens/FingerprintExperience/Fingerprint.const";

// TODO: MAKE IT SO THAT THESE CAN BE NAVIGABLE ONCE THEY'RE COMPLETED
function PurchaseSteps(props: any) {
  return (
    <Row className={`purchase-steps ${props.step}`}>
      <Link to="/purchase-quantity" className="step-quantity">
        Quantity
      </Link>
      <span className="line payment-line" />
      <Link to="/purchase-payment" className="step-payment">
        Payment
      </Link>
      <span className="line confirmation-line" />
      <Link to="/purchase-confirmation" className="step-confirmation">
        Confirmation
      </Link>
      <span className="line completion-line" />
      <Link to="/purchase-completion" className="step-completion">
        Completion
      </Link>
    </Row>
  );
}

type Props = {
  className?: string;
  background?: "blue" | "darkblue" | "lightgrey" | "navy";
  showLogo?: boolean;
  footer?: boolean;
  purchaseStep?: string;
  largeTitle?: string;
  viewLabel?: string;
  children?: ReactNode;
};

export const FingerPrintView: FC<Props> = ({
  background,
  showLogo = true,
  children,
  footer,
  purchaseStep,
  largeTitle,
  viewLabel,
  className,
}) => {
  const purchaseStepsExist = purchaseStep && purchaseStep !== "";

  const { surveyData, additionalQuestionsRequested } =
    useContext(FingerprintContext);

  const showProgress =
    window.location.pathname === "/digital-fingerprint/earliest-memory";

  return (
    <div className={`lapin-fingerprint bg-${background} ${className}`}>
      <div className="fingerprint-view-header">
        {showLogo && (
          <div className="fingerprint-logo">
            <Logo useBlueLogo={background === "lightgrey"} />
          </div>
        )}

        {showProgress && (
          <ProgressBar
            step={surveyData.length}
            stepAmount={QUESTIONS_AMOUNT_BASIC + additionalQuestionsRequested}
          />
        )}
      </div>

      <div className="fingerprint-content">
        {viewLabel !== undefined && (
          <span className="view-label">{viewLabel}</span>
        )}

        {largeTitle !== undefined && (
          <h1 className="large-title">{largeTitle}</h1>
        )}

        {purchaseStepsExist && <PurchaseSteps step={purchaseStep} />}

        {children}

        {footer && (
          <footer className="lapin-footer">
            <Row>
              <span className="copyright">
                Lapin © Copyright 2021. All Rights Reserved. Privacy Policy.
              </span>
            </Row>
          </footer>
        )}
      </div>
    </div>
  );
};
