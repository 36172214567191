export function convertToString(array: Array<string>) {
  let string = "";

  array.forEach((value, index) => {
    if (index != 0) {
      string = string + "," + value;
    } else {
      string = string + value;
    }
  });

  return string;
}
