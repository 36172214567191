import { connect, ConnectedProps } from "react-redux";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { FormikProps, Form, withFormik } from "formik";
import * as Yup from "yup";
import { MessageBox } from "../../helpers";
import { userActions } from "../../../actions/login.action";
import { RootState } from "../../../app/store";
import { ArrowIcon } from "../../../features/icons/Icons";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { Link } from "react-router-dom";

const mapState = (state: RootState) => {
  const { _processing, _errors } = state.authentication;
  return { _processing, _errors };
};

const actionCreators = {
  login: userActions.login,
};

const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface PurchasePaymentFormValues {
  credit_card_number: number;
  expiration_date: number;
  ccv: number;
  billing_address: string;
  country: string;
  state: string;
  city: string;
  postal_code: number;
}

interface OtherProps extends PropsFromRedux {
  message?: string;
}

function InnerPurchasePaymentForm(
  props: OtherProps & FormikProps<PurchasePaymentFormValues>
) {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    _errors,
    _processing,
  } = props;

  return (
    <FingerPrintView
      viewLabel="Lapin Leadership Fingerprint™ Assessment"
      largeTitle="Purchase Assessments"
      background="lightgrey"
      purchaseStep="payment"
    >
      <Form noValidate id="purchase_payment" className="purchase-payment">
        {_errors && <MessageBox variant="danger" messages={_errors} />}
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <FormLabel>Credit Card Number</FormLabel>
              <FormControl
                type="password"
                name="credit_card_number"
                placeholder="Credit Card Number…"
                className="quantity"
                //   onChange={handleChange}
                //   onBlur={handleBlur}
                value={values.credit_card_number}
              />
              {/* {touched.quantity && errors.quantity && (
                <div className="error-message">{errors.quantity}</div>
              )} */}
              {/* TODO: ADD PROMO CODE FIELD */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <FormGroup>
              <FormLabel>Credit Card Expiration Date</FormLabel>
              <FormControl
                type="text"
                name="expiration_date"
                placeholder="Expiration Date…"
                className="quantity"
                value={values.expiration_date}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <FormGroup>
              <FormLabel>Credit Card CCV</FormLabel>
              <FormControl
                type="text"
                name="ccv"
                placeholder="CCV…"
                className="ccv"
                value={values.ccv}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <FormGroup>
              <FormLabel>Billing Address</FormLabel>
              <FormControl
                type="text"
                name="billing_address"
                placeholder="Billing Address…"
                className="billing-address"
                value={values.billing_address}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <FormGroup>
              <FormLabel>Billing Country</FormLabel>
              <FormControl
                type="text"
                name="billing_country"
                placeholder="Country…"
                className="billing-country"
                value={values.country}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <FormGroup>
              <FormLabel>Billing State</FormLabel>
              <FormControl
                type="text"
                name="billing_state"
                placeholder="State…"
                className="billing-state"
                value={values.state}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <FormGroup>
              <FormLabel>Billing Country</FormLabel>
              <FormControl
                type="text"
                name="billing_country"
                placeholder="City…"
                className="billing-country"
                value={values.country}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <FormGroup>
              <FormLabel>Billing State</FormLabel>
              <FormControl
                type="text"
                name="billing_state"
                placeholder="Zip / Postal Code…"
                className="billing-state"
                value={values.state}
              />
            </FormGroup>
          </Col>
        </Row>
        {_processing && <div>Loading</div>}
        <Row className="bottom-options">
          <Link to="/account">Back</Link>
          <div className="total">Total: $1,000</div>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Confirm Purchase
            <ArrowIcon />
          </Button>
        </Row>
      </Form>
    </FingerPrintView>
  );
}

interface PurchasePaymentFormProps extends PropsFromRedux {
  quantity?: string;
  message?: string;
}

const PurchasePaymentForm = withFormik<
  PurchasePaymentFormProps,
  PurchasePaymentFormValues
>({
  // Transform outer props into form values
  // mapPropsToValues: (props) => {
  //   return {
  //     quantity: props.quantity || "admin@lapin.com", //remove this and set to blank
  //     firstName: props.firstName, //remove this and set to blank
  //     lastName: props.lastName,
  //   };
  // },

  // Add a custom validation function (this can be async too!)
  validationSchema: () => {
    const schema = Yup.object({
      quantity: Yup.string().required("quantity is a required field"),
    });
    return schema;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    console.log("registration submit");
  },
})(InnerPurchasePaymentForm);

const connectedPurchasePaymentPage = connector(PurchasePaymentForm);
export { connectedPurchasePaymentPage as PurchasePaymentForm };
