import {
  Row,
  Col,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { FingerPrintView } from "../../features/views/FingerPrintView/FingerPrintView";
import { ArrowIcon, CloseIcon } from "../../features/icons/Icons";
import "./Account.scss";
import { generateCheckoutSession } from "../../services/payments.service";
import React, { useEffect, useState, useRef } from "react";
import {
  getOrders,
  addLicenseToUser,
  addNewOrder,
  getLicenses,
  serverResponse,
  IError,
  statusToVariant,
  validateLicense,
} from "../../services/user.service";
import { IOrderDto } from "../../dtos/order.dto";
import { RootState } from "../../app/store";
import { userActions } from "../../actions/login.action";
import { ILicenseDto, LicenseStatus } from "../../dtos/license.dto";
import SessionService from "../../services/session.service";
import { useHistory } from "react-router";
import { MessageBox } from "../../modules/helpers/message-box/message-box";
import { useErrorHandler } from "../../app/hooks";

const mapState = (state: RootState) => {
  const { _processing, _errors, user } = state.authentication;
  return { _processing, _errors, user };
};

const actionCreators = {
  logout: userActions.logout,
};
const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

function LicenseBlockHeader(props: any) {
  let editRef = useRef<HTMLInputElement>(document.createElement("input"));
  const empty = props.orders?.length == 0 ? true : false;
  const { updateHttpError } = useErrorHandler();
  return (
    <div
      className={`account-block ${props.className}`}
      style={empty ? { borderBottom: "0px" } : {}}
    >
      <div
        style={empty ? { borderBottom: "0px" } : {}}
        className="licenses-header"
      >
        <h2>{props.title}</h2>

        {props.addLicense && (
          <div className="licenses-enter-key">
            <input
              ref={editRef}
              type="text"
              placeholder="Enter New Assessment Key Here..."
            />
            <ArrowIcon
              color="#3c78e6"
              handleClick={() => {
                const newLicense = async () => {
                  let newLicense = await addLicenseToUser(
                    editRef.current.value
                  );
                  props.setNewLicense(newLicense);
                };
                newLicense()
                  .catch((error) => {
                    updateHttpError({
                      messages: (error as IError).messages,
                      statusCode: (error as IError).statusCode,
                    });
                  })
                  .finally(() => {
                    editRef.current.value = "";
                  });
              }}
            />
          </div>
        )}
      </div>

      {props.children}
    </div>
  );
}

function AccountBlock(props: any) {
  return (
    <div className={`account-block ${props.className}`}>
      <h2>{props.title}</h2> {props.children}
    </div>
  );
}

const Account = (props: any) => {
  //const licenses = ["10094865", "10099253", "10100044"];
  const history = useHistory();
  const { updateHttpError } = useErrorHandler();
  const [orders, setOrders] = useState<IOrderDto[]>([]);
  const [licenses, setLicenses] = useState<ILicenseDto[]>([]);
  const [addedNewLicense, setAddedNewLicense] = useState<boolean>(false);

  const setSessionLicense = (licenseCode: string, licenseStatus: string) => {
    SessionService.getInstance().setLicense(licenseCode, licenseStatus);
  };
  const fetchLicenses = async () => {
    try {
      const data = await getLicenses();
      setLicenses(data.result);
    } catch (error: any) {
      updateHttpError({
        messages: (error as IError).messages,
        statusCode: (error as IError).statusCode,
      });
    }
  };

  const fetchOrders = async () => {
    try {
      const data = await getOrders();
      setOrders(data.result);
    } catch (error) {
      updateHttpError({
        messages: (error as IError).messages,
        statusCode: (error as IError).statusCode,
      });
    }
  };

  useEffect(() => {
    const startUp = async () => {
      await fetchLicenses();
      await fetchOrders();
    };
    startUp();
  }, []);

  useEffect(() => {
    SessionService.getInstance().clearLicense();
  }, []);

  const onPurchaseClick = async () => {
    try {
      const checkoutSession = await generateCheckoutSession();
      window.location.href = checkoutSession.url;
    } catch (error) {
      updateHttpError({
        messages: (error as IError).messages,
        statusCode: (error as IError).statusCode,
      });
    }
  };

  const handleLogout = () => {
    props.logout();
    window.location.replace("/login");
  };

  const message: string = `Hello ${props.user?.firstName}, you are currently logged on as `;

  const startMessageMap: { [key: string]: string } = {
    completed: "Completed",
    used: "Continue",
    active: "Start",
  };

  const handleAddLicense = (newLicense: ILicenseDto) => {
    setTimeout(() => {
      setAddedNewLicense(!addedNewLicense);
      tbody.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 1000);
  };

  let tbody = useRef<HTMLTableSectionElement>(document.createElement("tbody"));

  useEffect(() => {
    fetchLicenses();
  }, [addedNewLicense]);

  const { user } = SessionService.getInstance();

  return (
    <FingerPrintView
      viewLabel="Lapin Leadership Fingerprint™ Assessment"
      largeTitle="My Account"
      background="lightgrey"
      footer
    >
      <Row>
        <Col>
          <LicenseBlockHeader
            title="Assessment Keys"
            className="licenses"
            orders={orders}
            addLicense={true}
            setNewLicense={(license: ILicenseDto) => handleAddLicense(license)}
          >
            {licenses.length > 0 && (
              <table>
                <tbody ref={tbody}>
                  <tr>
                    <td style={{ color: "#82aaf9" }}>Key Code</td>
                    <td style={{ color: "#82aaf9" }}>Assigned to</td>
                    <td style={{ color: "#82aaf9" }}>Status</td>
                  </tr>

                  {licenses.map((license, i) => (
                    <React.Fragment key={`order-${i}`}>
                      <tr key={`license-${i}`}>
                        <td style={{ fontFamily: "monospace" }}>
                          {license.code}
                        </td>
                        <td style={{ fontFamily: "monospace" }}>
                          {license.user
                            ? license.user.fullName
                            : "No active user"}
                        </td>
                        <td>
                          <span id="status">
                            {startMessageMap[license.status]}
                          </span>
                          {license.userId === user?.id && (
                            <React.Fragment>
                              <ArrowIcon
                                color="#3c78e6"
                                handleClick={() => {
                                  setSessionLicense(
                                    license.code,
                                    license.status
                                  );

                                  history.push(
                                    license.status === "completed"
                                      ? `/fingerprint/${license.code}`
                                      : "/digital-fingerprint/before-we-begin"
                                  );
                                }}
                              />
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}

            {licenses.length < 1 && (
              <React.Fragment>
                <div className="account-info">
                  <p>
                    It seems you do not have a key to begin an assessment.
                    Please enter an Assessment Key above or purchase one below
                    to begin.
                  </p>
                </div>
              </React.Fragment>
            )}
          </LicenseBlockHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <LicenseBlockHeader
            title="Orders"
            className="licenses"
            orders={orders}
          >
            {orders.length > 0 && (
              <table>
                <tbody>
                  <tr>
                    <td style={{ color: "#82aaf9" }}>Order ID</td>
                    <td style={{ color: "#82aaf9" }}>Order Date</td>
                    <td style={{ color: "#82aaf9" }}>Price</td>
                    <td style={{ color: "#82aaf9" }}>License Count</td>
                  </tr>
                  {orders.map((order, i) => (
                    <React.Fragment key={`order-${i}`}>
                      <tr>
                        <td>{order.id}</td>
                        <td>
                          {new Date(order.timestamp).toLocaleDateString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {(order.total / 100).toFixed(2)}
                        </td>
                        <td>
                          {
                            order.licenses.filter(
                              (licens) => licens.status === "active"
                            ).length
                          }
                          /{order.licenseCount}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}

            {orders.length < 1 && (
              <React.Fragment>
                <div className="account-info">
                  <p>
                    It seems you have not placed any orders for assessment keys.
                  </p>
                </div>
              </React.Fragment>
            )}
          </LicenseBlockHeader>
        </Col>
      </Row>
      <Row style={{ marginTop: 50 }}>
        <Col>
          <Button className="more-assessments" onClick={onPurchaseClick}>
            Purchase New Assessment Key <ArrowIcon color="#3c78e6" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <AccessCode
            setNewLicense={(license: ILicenseDto) => handleAddLicense(license)}
          />
        </Col>
      </Row>
      <Row>
        <div className="account-info">
          <p>
            {message}
            <span>{`${props.user?.email}.`}</span>
          </p>
        </div>
      </Row>
      <div onClick={handleLogout} className="logout-wrapper">
        <span onClick={handleLogout}>Logout</span>
      </div>
    </FingerPrintView>
  );
};

function AccessCode(props: any) {
  const { updateHttpError } = useErrorHandler();
  let editRef = useRef<HTMLInputElement>(document.createElement("input"));
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="access-code">
      {!isVisible && (
        <Button className="more-assessments" onClick={() => setIsVisible(true)}>
          Use Access Code <ArrowIcon color="#3c78e6" />
        </Button>
      )}

      {isVisible && (
        <div className="form-wrapper">
          <input ref={editRef} type="text" placeholder="Enter a coupon code" />
          <ArrowIcon
            color="#3c78e6"
            handleClick={() => {
              const newLicense = async () => {
                let newLicense = await validateLicense(editRef.current.value);
                props.setNewLicense(newLicense);
              };
              newLicense()
                .catch((error) => {
                  updateHttpError({
                    messages: (error as IError).messages,
                    statusCode: (error as IError).statusCode,
                  });
                })
                .finally(() => {
                  editRef.current.value = "";
                  setIsVisible(false);
                });
            }}
          />
          <CloseIcon color="#3c78e6" handleClick={() => setIsVisible(false)} />
        </div>
      )}
    </div>
  );
}

const connectedAccount = connector(Account);
export { connectedAccount as Account };
