import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.scss";
import { TwoShapes } from "./features/shapes/Shapes";
import { pages } from "./features/progress-bar/progress-bar";
import { allActions, RootState } from "./app/store";
import { Routes } from "./Routes";
import SessionService from "./services/session.service";
import {
  getFingerprintData,
  IError,
  statusToVariant,
} from "./services/user.service";
import { useErrorHandler, useValueData } from "./app/hooks";
import { MessageBox } from "./modules/helpers/message-box/message-box";

const App = () => {
  const selectorError = useSelector((state: RootState) => {
    return state.editHttpError;
  });

  const [currentRoute, setCurrentRoute] = useState(
    window.location.pathname.replace("/", "")
  );

  const { updateHttpError } = useErrorHandler();
  const { CalculateValueScores } = useValueData();

  const dispatch = useDispatch();
  const selector = useSelector((state: RootState) => {
    return state.editForm;
  });

  const sendRoutesToParent = () => {
    setCurrentRoute(window.location.pathname.replace("/", ""));
  };

  const findPotentialValues = (data: any): string => {
    let potentialValues: string = "";
    let addedValues: string = "";

    if (data["potential-values"]) {
      potentialValues = data["potential-values"]["potential-values"];
    }
    if (data["add-values"]) {
      addedValues = data["add-values"]["add-values"];
    }
    if (!data["add-values"]) {
      return potentialValues;
    }

    if (addedValues === "" && potentialValues === "") {
      return "";
    }

    const route = "mapping-question";

    let values: Array<string> = [];
    if (data[route] !== undefined) {
      (potentialValues + "," + addedValues).split(",").map((value, index) => {
        if (
          data[route][value] !==
            "On further reflection, I do not value this." &&
          value !== ""
        ) {
          values.push(value);
        }
      });
    }

    return values.toString();
  };
  const fetchFormData = async () => {
    try {
      const data = await getFingerprintData(
        SessionService.getInstance().licenseCode
      );

      if (data.length > 0) {
        dispatch(allActions.formActions.readFromDB(JSON.parse(data[0].data)));
        let initialPage: string = data[0].lastPageAccessed;

        if (
          ![...pages, "craft-narrative", "confirm-fingerprint"].includes(
            data[0].lastPageAccessed
          )
        ) {
          initialPage = "about-you";
        }
        if (data[0].lastPageAccessed == null) {
          initialPage = "about-you";
        }
        if (data[0].visitedRoutes != null) {
          dispatch(
            allActions.appActions.loadRoutes(initialPage, data[0].visitedRoutes)
          );
        } else {
          dispatch(allActions.appActions.loadNewRoutes());
        }
      }
    } catch (error) {
      updateHttpError({
        messages: (error as IError).messages,
        statusCode: (error as IError).statusCode,
      });
    }
  };

  useEffect(() => {
    if (selector === null) {
      fetchFormData();
    } else {
      if (
        SessionService.getInstance().licenseCode !== undefined &&
        SessionService.getInstance().token !== undefined &&
        currentRoute.includes("digital-fingerprint/") &&
        !currentRoute.includes("not-very-much") &&
        Object.keys(selector).length === 0
      ) {
        fetchFormData();
      }
    }
  }, [currentRoute]);

  useEffect(() => {
    if (
      SessionService.getInstance().token !== null &&
      SessionService.getInstance().token !== undefined
    ) {
      fetchFormData();
    }
  }, [SessionService.getInstance().licenseCode]);

  useEffect(() => {
    if (selector !== null) {
      if (Object.keys(selector).length !== 0) {
        CalculateValueScores(findPotentialValues(selector));
      }
    }
  }, [selector]);

  return (
    <>
      <Router>
        <Route path="/">
          <Routes sendRoutesToParent={sendRoutesToParent} />
        </Route>
      </Router>

      {selectorError.statusCode > 399 && (
        <MessageBox
          messages={selectorError.messages}
          variant={statusToVariant(selectorError.statusCode)}
          class="absolute-message"
        />
      )}

      <TwoShapes currentPath={currentRoute} />
    </>
  );
};

export default App;
