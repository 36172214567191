import { FC } from "react";
import "./Loader.scss";

export const Loader: FC = () => {
  return (
    <>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};
