import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FingerprintContext } from "../FingerprintExperience/Fingerprint.context";
import SessionService from "../../services/session.service";
import { getNarrativeData } from "../../services/user.service";
import { FingerPrintView } from "../../features/views/FingerPrintView/FingerPrintView";
import { Button } from "react-bootstrap";
import { ArrowIcon } from "../../features/icons/Icons";
import { getAdditionalQuestionsRequested } from "../FingerprintExperience/Survey/Survey.fn";
import {
  ADDITIONAL_QUESTION_REQUESTED,
  QUESTIONS_AMOUNT_BASIC,
} from "../FingerprintExperience/Fingerprint.const";
import { ROUTES } from "../../app/routes";

export function BeforeWeBegin() {
  const history = useHistory();

  const { surveyData, setSurveyData, setAdditionalQuestionsRequested } =
    useContext(FingerprintContext);

  const [loading, setIsLoading] = useState(false);

  const { licenseCode } = SessionService.getInstance();

  const loadValues = async () => {
    if (!licenseCode) {
      return;
    }

    try {
      const narrativeData = await getNarrativeData(licenseCode);

      if (narrativeData?.surveyData) {
        setSurveyData(narrativeData?.surveyData);

        const additionalQuestions = getAdditionalQuestionsRequested(
          narrativeData.surveyData.length,
          QUESTIONS_AMOUNT_BASIC,
          ADDITIONAL_QUESTION_REQUESTED
        );

        setAdditionalQuestionsRequested(additionalQuestions);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadValues();
  }, []);

  const hasSurveyInProgress = surveyData?.length > 0;

  return (
    <FingerPrintView background="blue">
      <div className="content-wrapper">
        <h1>Before We Begin</h1>
        <p>
          You will be guided through a series of questions that will require
          thoughtful and honest answers. Be present, focused, and free from
          distractions. You get out what you put into this process, so be your
          authentic self.
        </p>

        <p>
          Take your time. You can leave and return whenever you like, picking
          back up where you left off.
        </p>

        <p>Enjoy!</p>

        {hasSurveyInProgress && (
          <p>
            &#9757; Looks like you have one in progress. Do you want to
            continue?
          </p>
        )}

        <div style={{ marginTop: 50 }}>
          {hasSurveyInProgress && (
            <Button
              variant="primary white"
              type="submit"
              style={{ marginRight: 20 }}
              onClick={() => {
                history.push("/digital-fingerprint/earliest-memory");
              }}
            >
              Continue
              <ArrowIcon color="#FFFFFF" />
            </Button>
          )}

          <Button
            variant="primary white"
            type="submit"
            onClick={() => {
              setSurveyData([]);
              setAdditionalQuestionsRequested(0);

              history.push(ROUTES.aboutYou);
            }}
          >
            Start {hasSurveyInProgress && "New"}
            <ArrowIcon color="#FFFFFF" />
          </Button>
        </div>
      </div>
    </FingerPrintView>
  );
}
