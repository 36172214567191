import React, { Fragment } from "react";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { Button, Row, Col } from "react-bootstrap";
import { ArrowIcon } from "../../../features/icons/Icons";
import { Link } from "react-router-dom";
import "./PurchaseConfirmation.scss";

function PurchaseDetail(props: any) {
  const details = props.details;

  return (
    <section className="purchase-details-confirmation">
      <div className="purchase-details">
        <div className="purchase-detail-header">
          <Col>
            <h2>{props.title}</h2>
          </Col>
          <Col>
            <Button className="edit">Edit</Button>
          </Col>
        </div>
        <div className="purchase-details-row">
          {details.map((detail: any, i: any) => {
            let detailValue = detail.value;
            return (
              <div className="purchase-detail" key={i}>
                <span className="label">{detail.label}</span>
                <span className="value">{detailValue}</span>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export function PurchaseConfirmation(props: any) {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    _errors,
    _processing,
  } = props;

  const paymentDetails = [
    { label: "Credit Card Number", value: [<Fragment>…3065</Fragment>] },
    { label: "Expiration Date", value: [<Fragment>00/00</Fragment>] },
    { label: "CCV", value: [<Fragment>123</Fragment>] },
    {
      label: "Billing Address",
      value: [
        <Fragment>
          2365 Familiar Street
          <br />
          City, State, 00000
          <br />
          United States
        </Fragment>,
      ],
    },
  ];

  const orderDetails = [
    {
      label: "Item",
      value: [
        <Fragment>
          <span className="no-break">Lapin Leadership Fingerprint™</span>
          <br />
          Assessment Key
        </Fragment>,
      ],
    },
    { label: "Unit Price", value: [<Fragment>$0.00</Fragment>] },
    { label: "Quantity", value: [<Fragment>3</Fragment>] },
  ];

  return (
    <FingerPrintView
      viewLabel="Lapin Leadership Fingerprint™ Assessment"
      largeTitle="Purchase Assessment Keys"
      background="lightgrey"
      purchaseStep="confirmation"
      className="purchase-confirmation"
    >
      <PurchaseDetail
        title="Confirm Payment Details"
        details={paymentDetails}
      />
      <PurchaseDetail title="Confirm Order Details" details={orderDetails} />
      <Row className="bottom-options">
        <Link to="/account">Back</Link>
        <div className="total">Total: $1,000</div>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          Place Order
          <ArrowIcon />
        </Button>
      </Row>
    </FingerPrintView>
  );
}
