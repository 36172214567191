export const formConstants = {
  ADD_DATA: "ADD_FORM_DATA",
  INITIAL: "INITIAL",
  DELETE_MAPPING: "DELETE_MAPPING",
};

const initialState: any = {};

export interface formState {
  [key: string]: { [key: string]: string };
}

interface formData {
  route: string;
  //singleResponse: string;
  data: { [key: string]: string };
}

const deleteValues = (initialState: formState) => {
  let currentFormState = { ...initialState };
  let valuesToDelete: Array<string> = [];
  if (currentFormState["mapping-question"]) {
    if (Object.keys(currentFormState["mapping-question"]).length > 0) {
      Object.keys(currentFormState["mapping-question"]).map((key, index) => {
        if (
          currentFormState["mapping-question"][key] ===
          "On further reflection, I do not value this."
        ) {
          valuesToDelete.push(key);
          delete currentFormState["mapping-question"][key];
        }
      });
    }
  }

  if (valuesToDelete.length > 0) {
    if (currentFormState["add-values"]) {
      let valuesArray = [
        ...currentFormState["add-values"]["add-values"].split(","),
      ];
      for (let i = 0; i < valuesToDelete.length; i++) {
        if (valuesArray.includes(valuesToDelete[i])) {
          valuesArray = valuesArray.filter(
            (value) => value !== valuesToDelete[i]
          );
        }
      }
      currentFormState["add-values"]["add-values"] = valuesArray.toString();
    }
    if (currentFormState["potential-values"]) {
      let potentialValuesArray =
        currentFormState["potential-values"]["potential-values"].split(",");

      for (let i = 0; i < valuesToDelete.length; i++) {
        if (potentialValuesArray.includes(valuesToDelete[i])) {
          potentialValuesArray = potentialValuesArray.filter(
            (value) => value !== valuesToDelete[i]
          );
        }
      }
      currentFormState["potential-values"]["potential-values"] =
        potentialValuesArray.toString();
    }
    currentFormState = deleteFromFinalValuesQuestions(
      valuesToDelete,
      currentFormState
    );
  }

  return currentFormState;
};

const deleteFromFinalValuesQuestions = (
  valuesToDelete: Array<string>,
  initialState: formState
): formState => {
  let currentState = { ...initialState };
  let tempArray: Array<string> = [];
  const valuesQuestionRoutes: Array<string> = [
    "personal-choice-values",
    "professional-choice-values",
    "opportunity-conflict-values",
    "disappointment-values",
  ];
  valuesQuestionRoutes.map((currentRoute, index) => {
    if (
      currentState[currentRoute] !== undefined &&
      currentState[currentRoute] !== null
    ) {
      tempArray = [...currentState[currentRoute][currentRoute].split(",")];
      currentState[currentRoute][currentRoute]
        .split(",")
        .map((response, index) => {
          if (valuesToDelete.includes(response)) {
            tempArray = tempArray.filter((value) => value !== response);
          }
        });
      currentState[currentRoute][currentRoute] = tempArray.toString();
    }
  });
  return currentState;
};

const readFromDB = (initialState: formState) => {
  const checkForDeletedValues = (initialState: formState) => {
    let currentFormState = deleteValues(initialState);

    return { type: formConstants.INITIAL, initialState: currentFormState };
  };
  return (dispatch: any) => {
    dispatch(checkForDeletedValues(initialState));
  };
};

export const editForm = (state = initialState, action: any): formState => {
  switch (action.type) {
    case formConstants.ADD_DATA:
      let currentData = { ...state };
      currentData[action.formData.route] = action.formData.data;
      if (action.formData.route.includes("mapping")) {
        if (action.formData.data) {
          Object.keys(action.formData.data).map((key, index) => {
            if (
              action.formData.data[key] ===
              "On further reflection, I do not value this."
            ) {
              currentData = deleteValues(currentData);

              delete currentData[action.formData.route][key];
            }
          });
        }
      }
      return currentData;
    case formConstants.INITIAL:
      return action.initialState;
    case formConstants.DELETE_MAPPING:
      let currentState = { ...state };
      if (
        currentState["mapping-question"] !== undefined &&
        currentState["mapping-question"] !== null
      ) {
        if (
          currentState["mapping-question"][action.value] !== undefined &&
          currentState["mapping-question"][action.value] !== null
        ) {
          delete currentState["mapping-question"][action.value];
        }
      }
      return currentState;
    default:
      return state;
  }
};

const addData = (formData: formData) => {
  const request = (formData: formData) => {
    return { type: formConstants.ADD_DATA, formData };
  };
  return (dispatch: any) => {
    dispatch(request(formData));
  };
};

const deleteMappingResponse = (value: string) => {
  const deleteMapping = (value: string) => {
    return { type: formConstants.DELETE_MAPPING, value };
  };

  return (dispatch: any) => {
    dispatch(deleteMapping(value));
  };
};

const addDataHook = (formData: formData) => {
  return { type: formConstants.ADD_DATA, formData };
};

export const formActions = {
  addData,
  addDataHook,
  readFromDB,
  deleteValues,
  deleteMappingResponse,
};
