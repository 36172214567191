import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { combineReducers, ThunkAction, Action } from "@reduxjs/toolkit";

import { authentication, userActions } from "../actions/login.action";
import { appRoute, appActions, dynRoutes } from "../actions/routes.action";
import { editForm, formActions } from "../actions/form.action";
import {
  editPotentialValues,
  editValues,
  valueActions,
} from "../actions/values.action";
import { editHttpError, errorActions } from "../actions/error.action";

const rootReducer = combineReducers({
  authentication,
  appRoute,
  editForm,
  editValues,
  editPotentialValues,
  editHttpError,
  dynRoutes,
});

const loggerMiddleware = createLogger();

export const allActions = {
  userActions,
  appActions,
  formActions,
  valueActions,
  errorActions,
};

export const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware)
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
