import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { useParams } from "react-router";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { FormikProps, Form, withFormik } from "formik";
import { ArrowIcon } from "../../../features/icons/Icons";
import * as Yup from "yup";
import { MessageBox } from "../../helpers";
import { recoverPassword } from "../../../services/user.service";

interface NewPasswordFormValues {
  email: string;
  newPassword: string;
  confirmNewPassword: string;
}

function InnerNewPasswordForm(props: FormikProps<NewPasswordFormValues>) {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    status,
  } = props;

  return (
    <FingerPrintView
      viewLabel="Lapin Leadership Fingerprint™ Assessment"
      largeTitle="Reset Password"
      background="lightgrey"
    >
      <Form
        id="forgot_password"
        className="forgot-password"
        onSubmit={handleSubmit}
      >
        {status._errors && (
          <MessageBox variant="danger" messages={status._errors} />
        )}
        {status._success && <MessageBox messages={status._success} />}
        <FormGroup>
          <FormLabel>Email</FormLabel>
          <span className="field-description">
            Confirm your email address to update your password
          </span>
          <FormControl
            type="text"
            name="email"
            placeholder="Email…"
            className={touched.email && errors.email ? "error" : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {touched.email && errors.email && (
            <div className="error-message">{errors.email}</div>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel>New Password</FormLabel>
          <span className="field-description">Enter your new password.</span>
          <FormControl
            type="password"
            name="newPassword"
            className={touched.newPassword && errors.newPassword ? "error" : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword}
          />
          {touched.newPassword && errors.newPassword && (
            <div className="error-message">{errors.newPassword}</div>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel>Confirm New Password</FormLabel>
          <span className="field-description">Confirm your new password</span>
          <FormControl
            type="password"
            name="confirmNewPassword"
            className={
              touched.confirmNewPassword && errors.confirmNewPassword
                ? "error"
                : ""
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmNewPassword}
          />
          {touched.confirmNewPassword && errors.confirmNewPassword && (
            <div className="error-message">{errors.confirmNewPassword}</div>
          )}
        </FormGroup>

        <Button variant="primary" type="submit" disabled={isSubmitting}>
          Submit
          <ArrowIcon />
        </Button>
      </Form>
    </FingerPrintView>
  );
}

interface NewPasswordFormProps {
  requestKey: string;
}

export const NewPasswordForm = withFormik<
  NewPasswordFormProps,
  NewPasswordFormValues
>({
  mapPropsToStatus: (props) => {
    return {
      _errors: null,
      _success: false,
    };
  },
  validationSchema: () => {
    const schema = Yup.object({
      email: Yup.string()
        .required("Email is a required field")
        .email("Not a valid email address"),
      newPassword: Yup.string().required("Password is a required field"),
      confirmNewPassword: Yup.string()
        .required("Confirm Password is a required field")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    });
    return schema;
  },

  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const requestKey = props.requestKey.split("=")[1];
    try {
      const success = await recoverPassword(
        values.email,
        values.newPassword,
        requestKey
      );
      const message: string[] = [
        "Password Updated - Redirecting to Log In page.",
      ];
      setStatus({ _success: message });
      setTimeout(() => {
        window.location.replace("/login");
      }, 4000);
    } catch (e: any) {
      setStatus({ _errors: [e.messages] });
    }
    setSubmitting(false);
  },
})(InnerNewPasswordForm);
