import { Switch, Route, useRouteMatch } from "react-router-dom";
import {
  AboutYou,
  ProfessionalLife,
  FirstAFewQuestions,
  VideoValueDrivers,
  ConfirmFingerprint,
} from "../../modules/fingerprint-steps/fingerprint-form";
import { FingerprintMapping } from "../../modules/fingerprint-steps/fingerprint-mapping";
import { useFormData, useValueData } from "../../app/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allActions, RootState } from "../../app/store";
import { Survey } from "./Survey/Survey";
import { PotentialValues } from "./Survey/PotentialValues/PotentialValues";
import { FingerprintContext } from "./Fingerprint.context";
import { AddValues } from "./Survey/AddValues/AddValues";
import { VideoValueMapping } from "./Survey/VideoValueMapping/VideoValueMapping";
import { ValuesOrder } from "./Survey/ValuesOrder/ValuesOrder";
import { getLocalStorageObject } from "../../utils/localStorage";
import { BeforeWeBegin } from "../BeforeWeBegin/BeforeWeBegin";
import { FingerPrintResult } from "./FingerprintResult/FingerPrintResult";
import SessionService from "../../services/session.service";

const STORAGE_KEY = "Lapin: Survey Data";

export const Fingerprint = () => {
  // Warn user about losing survey progress
  useEffect(() => {
    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { selector, getPotentialValues } = useFormData();
  const { selectorValues, CalculateValueScores } = useValueData();
  const routesSelector = useSelector((state: RootState) => {
    return state.appRoute;
  });
  const [newRoutes, setNewRoutes] = useState<JSX.Element[]>([
    <React.Fragment></React.Fragment>,
  ]);

  const { user } = SessionService.getInstance();

  const surveyResultsStorage = getLocalStorageObject(
    STORAGE_KEY,
    "surveyResults",
    user?.id || 0
  );

  const [additionalQuestionsRequested, setAdditionalQuestionsRequested] =
    useState(0);
  const [surveyData, setSurveyData] = useState<any[]>([]);
  const [selectedValuesByUser, setSelectedValuesByUser] = useState<any[]>([]);
  const [surveyResults, setSurveyResults] = useState<any>(
    surveyResultsStorage || {}
  );
  const [surveyResultsFinal, setSurveyResultsFinal] = useState<any>({});
  const [valuePhilosophy, setValuePhilosophy] = useState<string>("");

  // TODO: not used?
  useEffect(() => {
    if (selectorValues !== null) {
      setNewRoutes(
        Object.keys(selectorValues).map((value, index) => {
          let back: string = ``;
          let next: string = ``;
          if (index == 0) {
            back = `video-values-mapping`;
          } else {
            back = `mapping-question-${index}`;
          }
          if (index == Object.keys(selectorValues).length - 1) {
            next = `test-your-value-drivers`;
          } else {
            next = `mapping-question-${index + 2}`;
          }

          return (
            <Route path={`${path}/mapping-question-${index + 1}`}>
              <FingerprintMapping
                name={value}
                next={next}
                back={back}
                current={`mapping-question-${index + 1}`}
                message={`Please choose the answer that feels most accurate when reflecting on your value driver of: ${value}`}
                questionType="mapping"
              />
            </Route>
          );
        })
      );
    }
  }, [selectorValues]);

  useEffect(() => {
    let newRoutesArray: Array<string> = [];
    if (newRoutes.length > 0) {
      newRoutes.map((value, index) => {
        if (
          value !== undefined &&
          value.props !== undefined &&
          value.props.path !== undefined
        ) {
          let currentPath: string = value.props.path;
          newRoutesArray.push(currentPath.replace("/digital-fingerprint/", ""));
        }
      });

      dispatch(allActions.appActions.updateDynamicRoutes(newRoutesArray));
    }
  }, [newRoutes]);

  useEffect(() => {
    const valueRoutes = [
      "core-value",
      "personal-choice-values",
      "professional-choice-values",
      "opportunity-conflict-values",
      "disappointment-values",
    ];
    if (
      valueRoutes.includes(routesSelector.appRoute) ||
      routesSelector.appRoute.includes("mapping-question")
    ) {
      CalculateValueScores(getPotentialValues());
    }
  }, [selector]);

  return (
    <FingerprintContext.Provider
      value={{
        surveyData,
        surveyResults,
        surveyResultsFinal,
        selectedValuesByUser,
        additionalQuestionsRequested,
        valuePhilosophy,
        setValuePhilosophy,
        setSurveyData,
        setSurveyResults,
        setSurveyResultsFinal,
        setSelectedValuesByUser,
        setAdditionalQuestionsRequested,
      }}
    >
      <Switch>
        <Route path="/digital-fingerprint/before-we-begin">
          <BeforeWeBegin />
        </Route>

        <Route path="/digital-fingerprint/about-you">
          <AboutYou />
        </Route>

        <Route path="/digital-fingerprint/your-professional-life">
          <ProfessionalLife />
        </Route>

        <Route path="/digital-fingerprint/a-few-questions">
          <FirstAFewQuestions />
        </Route>

        <Route path="/digital-fingerprint/earliest-memory">
          <Survey />
        </Route>

        <Route path="/digital-fingerprint/potential-values">
          <PotentialValues name={"potential-values"} />
        </Route>

        <Route path="/digital-fingerprint/video-value-drivers">
          <VideoValueDrivers />
        </Route>

        <Route path={"/digital-fingerprint/add-values"}>
          <AddValues name={"add-potential-values"} />
        </Route>

        <Route path="/digital-fingerprint/video-values-mapping">
          <VideoValueMapping />
        </Route>

        <Route path="/digital-fingerprint/value-order">
          <ValuesOrder />
        </Route>

        <Route path="/digital-fingerprint/confirm-fingerprint">
          <ConfirmFingerprint />
        </Route>

        <Route path="/digital-fingerprint/:licenseCode">
          <FingerPrintResult />
        </Route>

        {/* <Redirect to="/account" /> */}
      </Switch>
    </FingerprintContext.Provider>
  );
};
