import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactDom from "react-dom";

interface IMessageBoxProps {
  messages: string[];
  variant?: string;
  class?: string;
}

interface IDeleteValue {
  value: string;
  onClose(): any;
  onDelete(): any;
}

export const MessageBox = (props: IMessageBoxProps) => {
  const { messages, variant = "success" } = props;
  let includeLogout = false;
  console.log(props);
  return (
    <Alert variant={variant} className={props.class ? props.class : ""}>
      {messages?.length > 1 && (
        <ul>
          {messages.map((message, idx) => {
            if (message != "") {
              return <li key={`msg-${idx}`}>{message}</li>;
            }
          })}
        </ul>
      )}
      {messages?.length == 1 && (
        <span className="error">
          {messages[0] ? messages[0] : "Something went wrong"}
        </span>
      )}

      {/* {includeLogout && <span onClick={}>Logout</span>} */}
    </Alert>
  );
};

export const DeleteValueModal = (props: IDeleteValue) => {
  return ReactDom.createPortal(
    <div className="delete-overlay">
      <div className="delete-modal">
        <Container className="container-delete">
          <Row>
            <p>{`Are you sure you want to remove ${props.value}?`}</p>
          </Row>
          <Row>
            <Col>
              <Button onClick={props.onDelete}>Yes</Button>
            </Col>
            <Col>
              <Button onClick={props.onClose}>No</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>,
    document.getElementById("modal") as Element
  );
};
