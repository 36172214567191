import {
  ConflictMoment,
  OpportunityConflictValues,
  PersonalChoiceValues,
} from "../../modules/fingerprint-steps/fingerprint-form";
// import { CraftNarrative } from "../../modules/fingerprint-steps/fingerprint-narrative";
import "./Shapes.scss";

interface shapeSetting {
  yPosition?: string;
  shape1: shape;
  shape2: shape;
  shape3?: shape;
  shape4?: shape;
  shape5?: shape;
  shape6?: shape;
}

interface shape {
  shape: string;
  color: string;
  position: string;
  type: string;
  left?: number;
  right?: number;
}

function SixShapeClasser(shapes: any) {
  let s1 =
    shapes.shape1.shape +
    " x-" +
    shapes.shape1.position +
    " color-" +
    shapes.shape1.color +
    " type-" +
    shapes.shape1.type;
  let s2 =
    shapes.shape2.shape +
    " x-" +
    shapes.shape2.position +
    " color-" +
    shapes.shape2.color +
    " type-" +
    shapes.shape2.type;
  let s3 =
    shapes.shape3.shape +
    " x-" +
    shapes.shape3.position +
    " color-" +
    shapes.shape3.color +
    " type-" +
    shapes.shape3.type;
  let s4 =
    shapes.shape4.shape +
    " x-" +
    shapes.shape4.position +
    " color-" +
    shapes.shape4.color +
    " type-" +
    shapes.shape4.type;
  let s5 =
    shapes.shape5.shape +
    " x-" +
    shapes.shape5.position +
    " color-" +
    shapes.shape5.color +
    " type-" +
    shapes.shape5.type;
  let s6 =
    shapes.shape6.shape +
    " x-" +
    shapes.shape6.position +
    " color-" +
    shapes.shape6.color +
    " type-" +
    shapes.shape6.type;
  let shapeClasses = {
    shape1: s1.toLowerCase(),
    shape2: s2.toLowerCase(),
    shape3: s3.toLowerCase(),
    shape4: s4.toLowerCase(),
    shape5: s5.toLowerCase(),
    shape6: s6.toLowerCase(),
  };
  return shapeClasses;
}
function TwoShapeClasser(shapes: any) {
  let yPosition = shapes.yPosition ? shapes.yPosition : "top";
  // let shapeClasses = {"y": yPosition};
  //let obj = {y: yPosition};
  // shapes.map(
  //   (
  //     value: { shape: string; position: string; color: string; type: string },
  //     index: number
  //   ) => {
  //     let s1 =
  //       value.shape +
  //       " x-" +
  //       value.position +
  //       " color-" +
  //       value.color +
  //       " type-" +
  //       value.type;
  //     let key = `shape${index + 1}`;
  //   }
  // );
  let s1 =
    shapes.shape1.shape +
    " x-" +
    shapes.shape1.position +
    " color-" +
    shapes.shape1.color +
    " type-" +
    shapes.shape1.type;
  let s2 =
    shapes.shape2.shape +
    " x-" +
    shapes.shape2.position +
    " color-" +
    shapes.shape2.color +
    " type-" +
    shapes.shape2.type;
  let shapeClasses = {
    y: yPosition,
    shape1: s1.toLowerCase(),
    shape2: s2.toLowerCase(),
  };
  return shapeClasses;
}

function ColorMap(classString: string) {
  let color: string = "lightgrey";
  const classArray = classString.split(" ");

  if (classArray.includes("show")) {
    classArray.forEach(function (shapeClass) {
      if (shapeClass.includes("color-")) {
        color = shapeClass.replace("color-", "");
      }
    });
  }

  interface Map {
    [key: string]: string | undefined;
  }

  const COLOR_MAP: Map = {
    white: "#ffffff",
    black: "#000000",
    lightgrey: "#F7F5F2",
    mediumgrey: "#D0D3D6",
    grey: "#D0D3D6",
    darkgrey: "#8B9197",
    lightblue: "#82AAF9",
    mediumblue: "#3C78E6",
    blue: "#3C78E6",
    darkblue: "#1A1A42",
  };

  return COLOR_MAP[color];
}

function Square(props: any) {
  return <span className={`shape css-shape square`}></span>;
}

function Circle(props: any) {
  return <span className={`shape css-shape circle`}></span>;
}

function LightMaze(props: any) {
  return (
    <span className={`shape svg-shape light-maze`}>
      <svg viewBox="0 0 146 146">
        <path
          id="Path_12"
          data-name="Path 12"
          d="M87.4,73A14.4,14.4,0,1,1,73,58.6V29.8a43.157,43.157,0,1,0,14.4,2.458V2.44A72.065,72.065,0,1,1,73,1"
          transform="translate(0.001)"
          fill="none"
          stroke="#D0D3D6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </span>
  );
}

function DenseMaze(props: any) {
  return (
    <span className={`shape svg-shape dense-maze`}>
      <svg viewBox="0 0 146 146">
        <path
          id="Path_10"
          data-name="Path 10"
          d="M87.4,73A14.4,14.4,0,1,1,73,58.6V44.2a28.787,28.787,0,1,0,14.4,3.853v-15.8A43.231,43.231,0,1,1,73,29.8V15.4a57.529,57.529,0,1,0,14.4,1.813V2.44A72.065,72.065,0,1,1,73,1"
          transform="translate(0.001)"
          fill="none"
          stroke="#D0D3D6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </span>
  );
}

function OuterMaze(props: any) {
  return (
    <span className={`shape svg-shape outer-maze`}>
      <svg viewBox="0 0 146 146">
        <path
          id="Path_11"
          data-name="Path 11"
          d="M116.2,73A43.2,43.2,0,1,1,73,29.8V15.4a57.529,57.529,0,1,0,14.4,1.813V2.44A72.065,72.065,0,1,1,73,1"
          transform="translate(0.001)"
          fill="none"
          stroke="#D0D3D6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </span>
  );
}

function Lines(props: any) {
  return (
    <span className={`shape svg-shape lines`}>
      <svg viewBox="0 0 146 146">
        <g id="Group_40" data-name="Group 40" transform="translate(0.001)">
          <line
            id="Line_4"
            data-name="Line 4"
            x1="143.999"
            transform="translate(0.999 87.4)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_5"
            data-name="Line 5"
            x1="143.999"
            transform="translate(0.999 73)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_6"
            data-name="Line 6"
            x1="143.999"
            transform="translate(0.999 116.2)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_7"
            data-name="Line 7"
            x1="143.999"
            transform="translate(0.999 130.6)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_8"
            data-name="Line 8"
            x1="143.999"
            transform="translate(0.999 58.6)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_9"
            data-name="Line 9"
            x1="143.999"
            transform="translate(0.999 44.2)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_10"
            data-name="Line 10"
            x1="143.999"
            transform="translate(0.999 29.8)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_11"
            data-name="Line 11"
            x1="143.999"
            transform="translate(0.999 15.4)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_12"
            data-name="Line 12"
            x1="143.999"
            transform="translate(0.999 101.8)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_13"
            data-name="Line 13"
            x2="144"
            transform="translate(0.999 1)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_14"
            data-name="Line 14"
            x1="144"
            transform="translate(0.999 145)"
            fill="none"
            stroke="#D0D3D6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </span>
  );
}

function shapeSettings(route: string) {
  const square_left_circle_right: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Square",
      color: "blue",
      position: "left",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "Circle",
      color: "white",
      position: "right",
      type: "outline",
      left: 0,
      right: 0,
    },
  };

  const square_right_circle_left: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Square",
      color: "blue",
      position: "right",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "Circle",
      color: "white",
      position: "left",
      type: "outline",
      left: 0,
      right: 0,
    },
  };

  const square_left_maze_right: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Square",
      color: "lightblue",
      position: "left",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "DenseMaze",
      color: "white",
      position: "right",
      type: "vector",
      left: 0,
      right: 0,
    },
  };

  const square_right_maze_left: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Square",
      color: "lightblue",
      position: "right",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "DenseMaze",
      color: "white",
      position: "left",
      type: "vector",
      left: 0,
      right: 0,
    },
  };

  const register: shapeSetting = {
    shape1: {
      shape: "Circle",
      color: "grey",
      position: "left",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "DenseMaze",
      color: "lightblue",
      position: "right",
      type: "vector",
      left: 0,
      right: 0,
    },
  };
  const login: shapeSetting = {
    shape1: {
      shape: "Square",
      color: "grey",
      position: "left",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "Circle",
      color: "blue",
      position: "right",
      type: "outline",
      left: 0,
      right: 0,
    },
  };
  const account: shapeSetting = {
    shape1: {
      shape: "Square",
      color: "lightblue",
      position: "right",
      type: "solid",
    },
    shape2: {
      shape: "lightMaze",
      color: "lightgrey",
      position: "left",
      type: "vector",
      left: -12.5,
    },
  };
  const forgotPassword: shapeSetting = {
    shape1: {
      shape: "Square",
      color: "grey",
      position: "right",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "Circle",
      color: "blue",
      position: "left",
      type: "outline",
      left: 0,
      right: 0,
    },
  };
  const beforeWeBegin: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "lightblue",
      position: "right",
      type: "solid",
    },
    shape2: {
      shape: "Lines",
      color: "white",
      position: "left",
      type: "vector",
      left: -12.5,
    },
  };
  const aboutYou: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "blue",
      position: "right",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "DenseMaze",
      color: "lightblue",
      position: "left",
      type: "vector",
      left: 0,
      right: 0,
    },
  };
  const yourProfessionalLife: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "blue",
      position: "left",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "DenseMaze",
      color: "lightblue",
      position: "right",
      type: "vector",
      left: 0,
      right: 0,
    },
  };
  const aFewQuestions = square_left_circle_right;

  const earliestMemory = square_right_circle_left;

  const yourParents = square_left_circle_right;
  const meaningfulRelationship = square_right_circle_left;
  const bestBoss = square_left_circle_right;
  const conflictMoment = square_right_circle_left;

  const videoValueDrivers = square_right_maze_left;
  const potentialValues = square_left_maze_right;
  const addValues = square_left_maze_right;
  const coreValue = square_right_maze_left;

  const videoValuesMapping: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "blue",
      position: "right",
      type: "solid",
    },
    shape2: {
      shape: "Lines",
      color: "lightblue",
      position: "left",
      type: "vector",
      left: -12.5,
    },
  };

  const mappingQuestion1: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "blue",
      position: "left",
      type: "solid",
      left: -12.5,
    },
    shape2: {
      shape: "Lines",
      color: "lightblue",
      position: "right",
      type: "vector",
    },
  };

  const mappingQuestion2: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "blue",
      position: "right",
      type: "solid",
    },
    shape2: {
      shape: "Lines",
      color: "lightblue",
      position: "left",
      type: "vector",
      left: -12.5,
    },
  };

  const testYourValueDrivers: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "white",
      position: "left",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "Circle",
      color: "blue",
      position: "right",
      type: "outline",
      left: 0,
      right: 0,
    },
  };

  const personalChoice: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "white",
      position: "right",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "Circle",
      color: "blue",
      position: "left",
      type: "outline",
      left: 0,
      right: 0,
    },
  };

  const personalChoiceValues: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Square",
      color: "blue",
      position: "left",
      type: "solid",
      left: -15,
    },
    shape2: {
      shape: "LightMaze",
      color: "lightblue",
      position: "right",
      type: "vector",
    },
  };

  const professionalChoice: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "white",
      position: "left",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "Circle",
      color: "blue",
      position: "right",
      type: "outline",
      left: 0,
      right: 0,
    },
  };

  const professionalChoiceValues: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Square",
      color: "blue",
      position: "right",
      type: "solid",
    },
    shape2: {
      shape: "LightMaze",
      color: "lightblue",
      position: "left",
      type: "vector",
      left: -17.5,
    },
  };

  const opportunityConflict: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "white",
      position: "right",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "Circle",
      color: "blue",
      position: "left",
      type: "outline",
      left: 0,
      right: 0,
    },
  };

  const opportunityConflictValues: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Square",
      color: "blue",
      position: "left",
      type: "solid",
      left: -17.5,
    },
    shape2: {
      shape: "LightMaze",
      color: "lightblue",
      position: "right",
      type: "vector",
    },
  };

  const disappointment: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "white",
      position: "right",
      type: "solid",
    },
    shape2: {
      shape: "Circle",
      color: "blue",
      position: "left",
      type: "outline",
    },
  };

  const dissapointmentValues: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Square",
      color: "blue",
      position: "right",
      type: "solid",
      right: 0,
    },
    shape2: {
      shape: "LightMaze",
      color: "lightblue",
      position: "left",
      type: "vector",
      left: -17.5,
    },
  };
  const six_shapes: shapeSetting = {
    shape1: {
      shape: "LightMaze",
      color: "lightblue",
      position: "left",
      type: "vector",
      left: 0,
      right: 0,
    },
    shape2: {
      shape: "Square",
      color: "lightblue",
      position: "right",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape3: {
      shape: "Lines",
      color: "white",
      position: "left",
      type: "vector",
    },
    shape4: {
      shape: "Circle",
      color: "blue",
      position: "right",
      type: "solid",
      left: 0,
      right: 0,
    },
    shape5: {
      shape: "DenseMaze",
      color: "lightblue",
      position: "right",
      type: "vector",
      left: 0,
      right: 0,
    },
    shape6: {
      shape: "OuterMaze",
      color: "white",
      position: "left",
      type: "outline",
      left: 0,
      right: 0,
    },
  };
  const calculatingValues = six_shapes;

  const buildingFingerprint = six_shapes;

  const craftNarrative: shapeSetting = {
    yPosition: "bottom",
    shape1: {
      shape: "Circle",
      color: "blue",
      position: "left",
      type: "solid",
    },
    shape2: {
      shape: "Lines",
      color: "lightblue",
      position: "right",
      type: "vector",
      right: 10,
    },
  };

  // if (route.includes("fingerprint/")) {
  //   return calculatingValues;
  // }

  switch (route) {
    default:
      return register;
    case "registration":
      return register;
    case "login":
      return login;
    case "account":
      return account;
    case "forgot-password":
      return forgotPassword;
    case "before-we-begin":
      return beforeWeBegin;
    case "about-you":
      return aboutYou;
    case "your-professional-life":
      return yourProfessionalLife;
    case "a-few-questions":
      return aFewQuestions;
    case "earliest-memory":
      return earliestMemory;
    case "your-parents":
      return yourParents;
    case "meaningful-relationship":
      return meaningfulRelationship;
    case "best-boss":
      return bestBoss;
    case "conflict-moment":
      return conflictMoment;
    case "video-value-drivers":
      return videoValueDrivers;
    case "calculating-potential-values":
      return calculatingValues;
    case "potential-values":
      return potentialValues;
    case "add-values":
      return addValues;
    case "core-value":
      return coreValue;
    case "video-values-mapping":
      return videoValuesMapping;
    case "mapping-question-1":
      return mappingQuestion1;
    case "mapping-question-2":
      return mappingQuestion2;
    case "test-your-value-drivers":
      return testYourValueDrivers;
    case "personal-choice":
      return personalChoice;
    case "personal-choice-values":
      return personalChoiceValues;
    case "professional-choice":
      return professionalChoice;
    case "professional-choice-values":
      return professionalChoiceValues;
    case "opportunity-conflict":
      return opportunityConflict;
    case "opportunity-conflict-values":
      return opportunityConflictValues;
    case "disappointment":
      return disappointment;
    case "disappointment-values":
      return dissapointmentValues;
    case "calculating-values":
      return calculatingValues;
    case "building-fingerprint":
      return buildingFingerprint;
    case "craft-narrative":
      return craftNarrative;
    case "your-fingerprint":
      return craftNarrative;
    case "your-fingerprint-calculating":
      return calculatingValues;
    case "fingerprint":
      return craftNarrative;
  }
}
const checkPublicNarrative = (route: string): string => {
  let returnString = "";
  if (route.includes("fingerprint/")) {
    returnString = "fingerprint/";
  }

  return returnString;
};

export function TwoShapes(props: any) {
  let route: string = "";
  let fixed: boolean = false;

  if (props.currentPath.includes("digital-fingerprint")) {
    route = props.currentPath.replace("digital-fingerprint/", "");
    fixed = true;
    // if (props.currentPath.includes("your-fingerprint")) {
    //   fixed = false;
    // }
  }

  if (props.currentPath.includes("fingerprint")) {
    route = props.currentPath.replace("fingerprint/", "");
    fixed = true;
  } else {
    route = props.currentPath;
  }

  const shapes = shapeSettings(route);

  const numShapes = Object.keys(shapes).length;
  if (numShapes == 6) {
    const fourShapesClasses = SixShapeClasser(shapes);
    return (
      <div className={`six-shapes`}>
        <div className="shape-wrapper">
          <div className={`shape-1 ${fourShapesClasses.shape1}`}>
            <Circle />
            <Square />
            <LightMaze />
            <DenseMaze />
            <OuterMaze />
            <Lines />
          </div>
          <div className={`shape-2 ${fourShapesClasses.shape2}`}>
            <Circle />
            <Square />
            <LightMaze />
            <DenseMaze />
            <OuterMaze />
            <Lines />
          </div>
          <div className={`shape-3 ${fourShapesClasses.shape3}`}>
            <Circle />
            <Square />
            <LightMaze />
            <DenseMaze />
            <OuterMaze />
            <Lines />
          </div>
          <div className={`shape-4 ${fourShapesClasses.shape4}`}>
            <Circle />
            <Square />
            <LightMaze />
            <DenseMaze />
            <OuterMaze />
            <Lines />
          </div>
          <div className={`shape-5 ${fourShapesClasses.shape5}`}>
            <Circle />
            <Square />
            <LightMaze />
            <DenseMaze />
            <OuterMaze />
            <Lines />
          </div>
          <div className={`shape-6 ${fourShapesClasses.shape6}`}>
            <Circle />
            <Square />
            <LightMaze />
            <DenseMaze />
            <OuterMaze />
            <Lines />
          </div>
        </div>
      </div>
    );
  } else {
    const shapesClasses = TwoShapeClasser(shapes);
    interface style {
      left?: string;
      right?: string;
    }
    let style1: style = {};
    let style2: style = {};

    if (shapes.shape1.left) {
      style1["left"] = `${shapes.shape1.left}%`;
    }
    if (shapes.shape1.right) {
      style1["right"] = `${shapes.shape1.right}%`;
    }
    if (shapes.shape2.left) {
      style2["left"] = `${shapes.shape2.left}%`;
    }
    if (shapes.shape2.right) {
      style2["right"] = `${shapes.shape2.right}%`;
    }

    const shapesY =
      props.currentPath.includes("digital-fingerprint") ||
      props.currentPath.includes("fingerprint")
        ? "bottom"
        : shapesClasses.y;

    return (
      <div className={`two-shapes y-${shapesY} ${fixed ? "fixed" : ""}`}>
        <div className="shape-wrapper">
          <div className={`shape-1 ${shapesClasses.shape1}`} style={style1}>
            <Circle />
            <Square />
            <LightMaze />
            <DenseMaze />
            <OuterMaze />
            <Lines />
          </div>
          <div className={`shape-2 ${shapesClasses.shape2}`} style={style2}>
            <Circle />
            <Square />
            <LightMaze />
            <DenseMaze />
            <OuterMaze />
            <Lines />
          </div>
        </div>
      </div>
    );
  }
}
