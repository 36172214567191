import { FC } from "react";
import logo from "../../assets/media/logo.svg"
import logoBlue from "../../assets/media/logo-blue.svg"

type Props = {
    useBlueLogo?: boolean;
}

export const Logo: FC<Props> = ({ useBlueLogo }) => {
    return <img src={useBlueLogo ? logoBlue : logo} onClick={() => {
        window.location.href = `${process.env.REACT_APP_EXPERIENCE_BASE_URL}/account`;
      }}/>
}