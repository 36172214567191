import { Dispatch, SetStateAction, createContext } from "react";

export type CurrentUser = {
  id: number;
  name: string;
  surname: string;
} | null;

interface Props {
  surveyData: any[];
  additionalQuestionsRequested: number; // User can ask for more questions
  surveyResults: any; // Survey results (main)
  surveyResultsFinal: any; // Survey results (after user changes values). TODO: refactor back-end endpoints to always use /survey/result
  selectedValuesByUser: string[]; // User can edit values
  valuePhilosophy: string;
  setValuePhilosophy: Dispatch<SetStateAction<string>>;
  setSelectedValuesByUser: Dispatch<SetStateAction<string[]>>;
  setSurveyData: (data: any[]) => void;
  setSurveyResults: (data: {}) => void;
  setSurveyResultsFinal: (data: {}) => void;
  setAdditionalQuestionsRequested: Dispatch<SetStateAction<number>>;
}

export const FingerprintContext = createContext<Props>({
  surveyData: [],
  additionalQuestionsRequested: 0,
  surveyResults: {},
  surveyResultsFinal: {},
  selectedValuesByUser: [],
  valuePhilosophy: "",
  setValuePhilosophy: () => {},
  setSelectedValuesByUser: () => {},
  setSurveyData: () => {},
  setSurveyResults: () => {},
  setSurveyResultsFinal: () => {},
  setAdditionalQuestionsRequested: () => {},
});
