import { ArrowIcon } from "../../../../../features/icons/Icons";

export function SingleOrderableValue(props: any) {
  return (
    <li>
      <span className="order-circle" />
      <div className="order-value-text">
        <h2>{props.valueName}</h2>
        {props.number === 0 && (
          <div className="explainer">
            <p>
              This is your <b>core value-driver</b>, the value-driver you are
              least likely to compromise in any situation. If you were placed in
              a position where two of your value-drivers conflicted, your core
              would be the one that always wins out.
            </p>
          </div>
        )}
        {props.number === 1 && (
          <div className="explainer">
            <p>
              This is the first <b>value-driver</b> that stems from your core
              driver. Once your core value-driver is nourished and fulfilled,
              this is the next most important value to you. Ensure that these
              value-drivers are ordered by which you would least likely
              sacrifice first.
            </p>
          </div>
        )}

        {props.number === 2 && (
          <div className="explainer">
            <p>
              Once "{props.previous}" has been fulfilled, this is the next value
              driver you are least likely to sacrifice.
            </p>
          </div>
        )}

        {props.number > 2 && !props.isLast && (
          <div className="explainer">
            <p>
              Once "{props.previous}" has been fulfilled, this is the next value
              driver you are least likely to sacrifice.
            </p>
          </div>
        )}

        {props.isLast && (
          <div className="explainer">
            <p>
              This is your <b>outcome value-driver</b>. This is how others will
              experience you when you are honoring all of your value-drivers. If
              your other value-drivers are not being nourished. then it's likely
              this value will be compromised and you will struggle to express
              it.
            </p>
            <p>
              For example, if honesty comes before kindness, then if you feel
              someone is being dishonest, you may struggle to show them
              kindness.
            </p>
          </div>
        )}

        <div className="ordering-buttons">
          {props.number !== 0 && (
            <button
              className="move-up"
              onClick={() => {
                props.move("upward", props.number);
              }}
            >
              move up <ArrowIcon direction="up" color="#8B9197" />
            </button>
          )}
          {!props.isLast && (
            <button
              className="move-down"
              onClick={() => {
                props.move("downward", props.number);
              }}
            >
              move down <ArrowIcon direction="down" color="#8B9197" />
            </button>
          )}
          {/* <button
            className="remove"
            onClick={() => {
              props.remove(props.number);
            }}
          >
            remove <span>✕</span>
          </button> */}
        </div>
      </div>
    </li>
  );
}
