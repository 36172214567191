import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";

export const appConstants = {
  APP_ROUTE: "APP_ROUTE",
  FORM_ROUTE: "FORM_ROUTE",
  LOAD_ROUTES: "LOAD_ROUTES",
  NEW_ROUTES: "NEW_ROUTES",
};

export const dynamicRouteConstants = {
  UPDATE_DYNAMIC_ROUTES: "UPDATE_DYNAMIC_ROUTES",
};

interface AppState {
  appRoute: string;
  nextRoute: string;
  backRoute: string;
  lastPageAccessed: string;
  visitedRoutes: Array<string>;
}

const initialState: AppState = {
  appRoute: window.location.pathname.replace("/", ""),
  nextRoute: window.location.pathname.replace("/", ""),
  backRoute: window.location.pathname.replace("/", ""),
  lastPageAccessed: window.location.pathname.replace("/", ""),
  visitedRoutes: [],
};

let dynamicRoutes: Array<string> = [];

const notSavedForLastPageAccesses: Array<string> = [
  "not-very-much",
  "payments/success",
  "payments/canceled",
];

const checkLastPageAccessed = (page: string) => {
  notSavedForLastPageAccesses.map((value, index) => {
    if (value.includes(page)) {
      return true;
    }
  });
  return false;
};

const updateVisitedRoutes = (currentState: AppState, newRoute: string) => {
  let newVisitedRoutes = [...currentState.visitedRoutes];
  if (
    !currentState.visitedRoutes.includes(newRoute) &&
    newRoute !== "not-very-much"
  ) {
    newVisitedRoutes = [...newVisitedRoutes, newRoute];
  }
  return newVisitedRoutes;
};

export const appRoute = (state = initialState, action: any): AppState => {
  switch (action.type) {
    case appConstants.APP_ROUTE:
      let currentState = { ...state };

      return {
        backRoute: currentState.backRoute,
        appRoute: action.newRoute,
        nextRoute: currentState.nextRoute,
        lastPageAccessed: checkLastPageAccessed(currentState.backRoute)
          ? currentState.lastPageAccessed
          : currentState.backRoute,
        visitedRoutes: updateVisitedRoutes({ ...state }, action.newRoute),
      };
    case appConstants.FORM_ROUTE:
      let prevState = { ...state };
      return {
        backRoute: action.backRoute,
        appRoute: action.currRoute,
        nextRoute: action.nextRoute,
        lastPageAccessed: checkLastPageAccessed(action.backRoute)
          ? prevState.lastPageAccessed
          : action.backRoute,
        visitedRoutes: updateVisitedRoutes({ ...state }, action.currRoute),
      };
    case appConstants.LOAD_ROUTES:
      return {
        backRoute: state.backRoute,
        appRoute: state.appRoute,
        nextRoute: state.nextRoute,
        lastPageAccessed: action.lastPageAccessed,
        visitedRoutes: action.visitedRoutes,
      };
    case appConstants.NEW_ROUTES:
      return {
        appRoute: window.location.pathname.replace("/", ""),
        nextRoute: window.location.pathname.replace("/", ""),
        backRoute: window.location.pathname.replace("/", ""),
        lastPageAccessed: window.location.pathname.replace("/", ""),
        visitedRoutes: [],
      };
    default:
      return state;
  }
};

export const dynRoutes = (
  state = dynamicRoutes,
  action: any
): Array<string> => {
  switch (action.type) {
    case dynamicRouteConstants.UPDATE_DYNAMIC_ROUTES:
      let currentRoutes: Array<string> = action.routes;
      return currentRoutes;
    default:
      return state;
  }
};

const loadNewRoutes = () => {
  let result = { type: appConstants.NEW_ROUTES };
  return (dispatch: any) => {
    dispatch(result);
  };
};

const updateAppRoute = (newRoute: string) => {
  let result = { type: appConstants.APP_ROUTE, newRoute };
  return (dispatch: any) => {
    dispatch(result);
  };
};

const updateFormLocation = (
  backRoute: string,
  currRoute: string,
  nextRoute: string
) => {
  let result = {
    type: appConstants.FORM_ROUTE,
    backRoute,
    currRoute,
    nextRoute,
  };
  return (dispatch: any) => {
    dispatch(result);
  };
};

const loadRoutes = (lastPageAccessed: string, visitedRoutes: string) => {
  const result = {
    type: appConstants.LOAD_ROUTES,
    lastPageAccessed: lastPageAccessed,
    visitedRoutes: visitedRoutes.split(","),
  };

  return (dispatch: any) => {
    dispatch(result);
  };
};

const updateDynamicRoutes = (routes: Array<string>) => {
  const result = { type: dynamicRouteConstants.UPDATE_DYNAMIC_ROUTES, routes };
  return (dispatch: any) => {
    dispatch(result);
  };
};

export const appActions = {
  updateAppRoute,
  updateFormLocation,
  loadRoutes,
  updateDynamicRoutes,
  loadNewRoutes,
};
