import { ISessionUserDto } from "../dtos/session-user.dto";
import SessionService from "../services/session.service";
import * as UserServices from "../services/user.service";

export const authConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",
};

let user = SessionService.getInstance().user;

interface ILoginActionState {
  user?: ISessionUserDto | undefined;
  authenticated: boolean;
  _processing?: boolean;
  _errors?: string[];
}

const initialState: ILoginActionState = user
  ? { authenticated: true, user }
  : { authenticated: false };

export const authentication = (
  state = initialState,
  action: any
): ILoginActionState => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        user: action.user,
        authenticated: false,
        _processing: true,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        authenticated: true,
        user: action.user,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        authenticated: false,
        user: action.user,
        _errors: action.error,
      };
    case authConstants.LOGOUT:
      return { authenticated: false };
    default:
      return state;
  }
};

const login = (username: string, password: string) => {
  const request = (user: any) => {
    return { type: authConstants.LOGIN_REQUEST, user };
  };

  const success = (user: any) => {
    return { type: authConstants.LOGIN_SUCCESS, user };
  };
  const failure = (error: any) => {
    return { type: authConstants.LOGIN_FAILURE, error };
  };

  return async (dispatch: any) => {
    dispatch(request({ username }));
    try {
      const user = await UserServices.login(username, password);

      dispatch(success(user));
    } catch (e: any) {
      dispatch(failure([e.messages]));
    }
  };
};

const logout = () => {
  UserServices.logout();
  const logOut = () => {
    return { type: authConstants.LOGOUT };
  };
  return async (dispatch: any) => {
    dispatch(logOut);
  };
};

export const userActions = {
  login,
  logout,
};
