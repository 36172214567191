import React from "react";
import {
  PlayerPlay,
  PlayerPause,
  PlayerSound,
  PlayerMute,
} from "../../../features/icons/Icons";
import "./CraftNarrative.scss";
import { AUDIO_GUIDE } from "../../../assets/media";

export function AudioGuide(props: any) {
  const audioRef = React.useRef<HTMLAudioElement>(null);

  return (
    <div className="audio">
      <audio controls ref={audioRef} autoPlay={true}>
        <source src={AUDIO_GUIDE.default} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <div className="audio-player">
        <div className="timeline">
          <div className="progress"></div>
        </div>
        <div className="controls">
          <div className="play-container">
            <div className="toggle-play play">
              <PlayerPlay />
              <PlayerPause />
            </div>
          </div>
          <div className="time">
            <div className="current">0:00</div>
            <div className="divider">/</div>
            <div className="length"></div>
          </div>
          <div className="volume-container">
            <div className="volume-button">
              <PlayerSound />
              <PlayerMute />
            </div>
            <div className="volume-slider">
              <div className="volume-percentage"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
