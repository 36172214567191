import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "./app/routes";

export const AllRoutes = () => {
  function linkify(routeString: any) {
    if (routeString !== "/digital-fingerprint") {
      return routeString
        .replace("/digital-fingerprint", "")
        .replace("-", " ")
        .replace("/", "");
    } else {
      return;
    }
  }

  const allRoutes = [
    "/register",
    "/registration",
    "/forgot-password",
    "/login",
    "/account",
    "/purchase-quantity",
    "/purchase-payment",
    "/purchase-confirmation",
    "/purchase-completion",
    "/enter-promo-code",
    "/digital-fingerprint/before-we-begin",
    ROUTES.aboutYou,
    ROUTES.professionalLife,
    "/digital-fingerprint/a-few-questions",
    "/digital-fingerprint/earliest-memory",
    "/digital-fingerprint/not-very-much",
    "/digital-fingerprint/your-parents",
    "/digital-fingerprint/meaningful-relationship",
    "/digital-fingerprint/best-boss",
    "/digital-fingerprint/conflict-moment",
    "/digital-fingerprint/video-value-drivers",
    "/digital-fingerprint/potential-values",
    "/digital-fingerprint/add-values",
    "/digital-fingerprint/core-value",
    "/digital-fingerprint/video-values-mapping",
    "/digital-fingerprint/mapping-question-1",
    "/digital-fingerprint/mapping-question-2",
    "/digital-fingerprint/test-your-value-drivers",
    "/digital-fingerprint/personal-choice",
    "/digital-fingerprint/personal-choice-values",
    "/digital-fingerprint/professional-choice",
    "/digital-fingerprint/professional-choice-values",
    "/digital-fingerprint/opportunity-conflict",
    "/digital-fingerprint/opportunity-conflict-values",
    "/digital-fingerprint/disappointment",
    "/digital-fingerprint/disappointment-values",
    "/digital-fingerprint/calculating-values",
    "/digital-fingerprint/value-order",
    "/digital-fingerprint/building-fingerprint",
    "/digital-fingerprint/confirm-fingerprint",
    "/digital-fingerprint/craft-narrative",
    "/digital-fingerprint/your-fingerprint",
  ];

  const reviewRoutes = [
    "/register",
    "/registration",
    "/forgot-password",
    "/login",
    "/purchase-quantity",
    "/purchase-payment",
    "/digital-fingerprint/before-we-begin",
    ROUTES.aboutYou,
    ROUTES.professionalLife,
    "/digital-fingerprint/a-few-questions",
    "/digital-fingerprint/earliest-memory",
    "/digital-fingerprint/not-very-much",
    "/digital-fingerprint/your-parents",
    "/digital-fingerprint/meaningful-relationship",
    "/digital-fingerprint/best-boss",
    "/digital-fingerprint/conflict-moment",
    "/digital-fingerprint/video-value-drivers",
    "/digital-fingerprint/video-values-mapping",
    "/digital-fingerprint/core-value",
    "/digital-fingerprint/add-values",
    "/digital-fingerprint/potential-values",
    "/digital-fingerprint/mapping-question-1",
    "/digital-fingerprint/mapping-question-2",
    "/digital-fingerprint/test-your-value-drivers",
    "/digital-fingerprint/personal-choice",
    "/digital-fingerprint/personal-choice-values",
    "/digital-fingerprint/professional-choice",
    "/digital-fingerprint/professional-choice-values",
    "/digital-fingerprint/opportunity-conflict",
    "/digital-fingerprint/opportunity-conflict-values",
    "/digital-fingerprint/disappointment",
    "/digital-fingerprint/disappointment-values",
  ];

  const location = useLocation();

  return (
    <div style={{ margin: "100px auto 0", width: 500, fontSize: 16 }}>
      <h1 style={{ marginBottom: 25 }}>All app routes</h1>

      <nav>
        <ul
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "0 60px",
          }}
        >
          {allRoutes.map((x, i) => (
            <li
              key={i}
              className={`nav-item-${i + 1} ${
                reviewRoutes.includes(x) ? "review" : "undone"
              } ${x === location.pathname ? "current" : ""}`}
            >
              <Link to={x}>{linkify(x)}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
