import { ISessionUserDto } from "../dtos/session-user.dto";

export class SessionService {
  static instance: SessionService;
  static storageKeyUser = "session-user";
  static storageKeyJWT = "session-jwt";
  static license = "license";

  static getInstance() {
    if (!SessionService.instance)
      SessionService.instance = new SessionService();

    return SessionService.instance;
  }

  token: string | undefined;
  user: ISessionUserDto | undefined;
  licenseCode: string | undefined;
  licenseStatus: string | undefined;

  constructor() {
    let sessionUser = localStorage.getItem(SessionService.storageKeyUser);
    if (sessionUser) this.user = JSON.parse(sessionUser);

    let sessionToken = localStorage.getItem(SessionService.storageKeyJWT);
    if (sessionToken) this.token = sessionToken;

    let sessionLicense = localStorage.getItem(SessionService.license);
    if (sessionLicense) {
      this.licenseCode = JSON.parse(sessionLicense).licenseCode;
      this.licenseStatus = JSON.parse(sessionLicense).licenseStatus;
    }
  }

  setUser(user: ISessionUserDto): SessionService {
    this.user = user;
    localStorage.setItem(SessionService.storageKeyUser, JSON.stringify(user));
    return this;
  }

  setToken(token: string): SessionService {
    this.token = token;
    localStorage.setItem(SessionService.storageKeyJWT, token);
    return this;
  }

  clear(): void {
    localStorage.removeItem(SessionService.storageKeyUser);
    localStorage.removeItem(SessionService.storageKeyJWT);
    localStorage.removeItem(SessionService.license);

    delete this.token;
    delete this.user;
    delete this.licenseCode;
    delete this.licenseStatus;
  }

  setLicense(licenseCode: string, licenseStatus: string) {
    this.licenseCode = licenseCode;
    this.licenseStatus = licenseStatus;
    localStorage.setItem(
      SessionService.license,
      JSON.stringify({ licenseCode, licenseStatus })
    );
  }

  clearLicense() {
    localStorage.removeItem(SessionService.license);
    this.licenseCode = undefined;
    this.licenseStatus = undefined;
  }

  isActive(): boolean {
    return (
      typeof this.token !== "undefined" && typeof this.user !== "undefined"
    );
  }
}

export default SessionService;
