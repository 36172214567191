export const valueConstants = {
  LOAD_DATA: "LOAD_DATA",
  LOAD_MAPPING: "LOAD_MAPPING",
  SELECTED_VALUES: "SELECTED_VALUES",
  ADD_TO_VALUE: "ADD_TO_VALUE",
  ADD_VALUE: "ADD_VALUE",
  DELETE_VALUE: "DELETE_VALUE",
};
export const potentialValueConstants = {
  LOAD_POTENTIAL_VALUES: "LOAD_POTENTIAL_VALUES",
};

export const finalNarrativeConstants = {
  LOAD_FINAL_NARRATIVE: "LOAD_FINAL_NARRATIVE",
};
export const finalValueConstants = {
  LOAD_FINAL_VALUES: "LOAD_FINAL_VALUES",
};

export interface valueState {
  [key: string]: number;
}

const valueMap: { [key: string]: number } = {
  "When making a decision, I never betray this value. I would sacrifice anything to uphold it.": 4,
  "This value is very important to me but I find myself betraying it every once in a while.": 3,
  "Though I think it's important, I struggle to live up to this value.": 1,
  "On further reflection, I do not value this.": 0,
};

let routeValuesLoaded: Array<string> = [];
let valuesMapped: Array<string> = [];
let coreValue: string = "";

const potentialValuesState: Array<string> = [];
const initialState: valueState = {};

export const editPotentialValues = (
  state = potentialValuesState,
  action: any
) => {
  switch (action.type) {
    case potentialValueConstants.LOAD_POTENTIAL_VALUES:
      let newValues = [...action.values];
      return newValues;
    default:
      return state;
  }
};

export const editValues = (state = initialState, action: any): valueState => {
  switch (action.type) {
    case valueConstants.LOAD_DATA:
      let initialValues = { ...state };
      action.values.forEach((value: string, index: number) => {
        if (value !== "") {
          initialValues[value] = initialValues[value] + action.amount;
        }
      });
      return initialValues;
    case valueConstants.LOAD_MAPPING:
      let values = { ...state };
      // if (action.option === 0) {
      //   if (values[action.value]) {
      //     delete values[action.value];
      //   }
      // } else {
      if (action.option !== 0) {
        values[action.value] = values[action.value]
          ? values[action.value] + action.option
          : action.option;
      }

      // }
      return values;
    case valueConstants.SELECTED_VALUES:
      // let newValues = { ...action.selectedValues };
      // let currentState = { ...state };
      // Object.keys(newValues).map((value, index) => {
      //   if (Object.keys(currentState).includes(value)) {
      //     newValues[value] = currentState[value];
      //   }
      // });

      // return newValues;
      return action.selectedValues;
    case valueConstants.ADD_TO_VALUE:
      let currentValues = { ...state };
      // if (action.option == 0) {
      //   delete currentValues[action.value];
      // } else {
      currentValues[action.value] = currentValues[action.value]
        ? currentValues[action.value] + action.option
        : action.option;
      //}
      return currentValues;
    case valueConstants.ADD_VALUE:
      let existingValues = { ...state };
      existingValues[action.newValue] = 0;
      return existingValues;
    case valueConstants.DELETE_VALUE:
      let exValues = { ...state };
      delete exValues[action.Value];
      return exValues;
    default:
      return state;
  }
};

const updateValuesOnFirstRender = (
  route: string,
  values: Array<string>,
  amount: number
) => {
  const setAction = () => {
    let action = {};
    // if (!routeValuesLoaded.includes(route)) {
    //routeValuesLoaded.push(route);
    action = {
      type: valueConstants.LOAD_DATA,
      values: values,
      amount: amount,
    };
    // } else {
    //   action = {
    //     type: "default",
    //   };
    // }
    return action;
  };

  return (dispatch: any) => {
    dispatch(setAction());
  };
};

const updateMappingValuesOnFirstRender = (
  route: string,
  value: string,
  option: string
) => {
  const setAction = () => {
    let action = {};
    // if (!valuesMapped.includes(value)) {
    //   valuesMapped.push(value);
    action = {
      type: valueConstants.LOAD_MAPPING,
      value,
      option: valueMap[option],
    };
    // } else {
    //   action = {
    //     type: "default",
    //   };
    // }
    return action;
  };

  return (dispatch: any) => {
    dispatch(setAction());
  };
};

const setSelectedValues = (selectedValueKeys: Array<string>) => {
  const setInitialScores = (selectedValueKeys: Array<string>) => {
    let selectedValues: { [key: string]: number } = {};
    selectedValueKeys.forEach((value, index) => {
      if (value !== "") {
        selectedValues[value] = 0;
      }
    });
    return { type: valueConstants.SELECTED_VALUES, selectedValues };
  };
  return (dispatch: any) => {
    dispatch(setInitialScores(selectedValueKeys));
  };
};

const appendValue = (name: string) => {
  let action = { type: valueConstants.ADD_VALUE, newValue: name };
  return (dispatch: any) => {
    dispatch(action);
  };
};

const removeValue = (name: string) => {
  let action = { type: valueConstants.DELETE_VALUE, Value: name };
  return (dispatch: any) => {
    dispatch(action);
  };
};

const addToValue = (
  value: string,
  option: string,
  prevOption: string = "On further reflection, I do not value this."
) => {
  return (dispatch: any) => {
    dispatch({
      type: valueConstants.ADD_TO_VALUE,
      value,
      option: prevOption
        ? valueMap[option] - valueMap[prevOption]
        : valueMap[option],
    });
  };
};

const addValidatingValue = (value: string, amount: number) => {
  return (dispatch: any) => {
    dispatch({
      type: valueConstants.ADD_TO_VALUE,
      value,
      option: amount,
    });
  };
};

const addToValueAndRoute = (route: string, value: string, amount: number) => {
  let action = {};

  // if (!routeValuesLoaded.includes(route)) {
  // routeValuesLoaded.push(route);
  action = {
    type: valueConstants.ADD_TO_VALUE,
    value,
    option: amount,
  };
  // } else {
  //   action = {
  //     type: "default",
  //   };
  // }
  return (dispatch: any) => {
    dispatch(action);
  };
};

const savePotentialValues = (newValues: Array<string>) => {
  let action = {
    type: potentialValueConstants.LOAD_POTENTIAL_VALUES,
    values: newValues,
  };
  return (dispatch: any) => {
    dispatch(action);
  };
};

const saveFinalNarrative = (narrative: string, values: string) => {};

export const valueActions = {
  updateMappingValuesOnFirstRender,
  updateValuesOnFirstRender,
  setSelectedValues,
  addToValue,
  addValidatingValue,
  addToValueAndRoute,
  appendValue,
  savePotentialValues,
  removeValue,
};
