import axios from "axios";
import SessionService from "./services/session.service";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Add JWT if available
API.interceptors.request.use(
  function (config) {
    const accessToken = SessionService.getInstance().token;
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (value) => value,
  (error) => {
    const data = error.response.data;
    if (!Array.isArray(data.messages)) data.messages = [data.messages];
    return Promise.reject(data);
  }
);

export default API;
