/**
 * Transform id-structured object to a simple list.
 * So it's capable for sorting, slicing, etc.
 * @example
 * { 236623: { timeStamp: 14 }, 348975: { timeStamp: 11 }} =>
 * [{ id: 234553, timeStamp: 13}, { id: 236623, timeStamp: 14}]
 * @param o Object with id-structured entries
 * @returns List of entries
 */
export function idObjectToList(o: {}) {
  const entries = Object.entries(o);

  if (entries.filter(([_, o]) => typeof o !== "object").length) {
    console.error(
      "Unexpected store structure. Please check the saving mechanism."
    );
  }

  return (
    entries
      // Expect objects like {2321: {view: "query"}
      // Incorrect structure in local storage can cause bugs in restore settings
      .filter(([_, o]) => typeof o === "object")
      .map(([id, o]) => ({
        id,
        ...(o as {}),
      }))
  );
}

/**
 * Update local storage value
 * @param key Local storage key
 * @param value New data to put in the local storage
 * @param id Additional split of the storage object by id. Can be used to separate users in store.
 * @example {2321: {view: "query"}, 7223: {view: "query"}}
 */
export function updateLocalStorage(
  key: string,
  value: number | string | [] | {},
  id?: number
) {
  // Prevent "undefined is not a valid JSON" exception
  if (value === undefined) {
    return;
  }

  if (id) {
    localStorage.setItem(key, JSON.stringify({ [id]: value }));
    return;
  }

  localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Update nested storage object
 *
 * @param key Local storage key
 *
 * @param updateObject This object will extend or replace the existing one
 * @example {view: "value", ...updateObject}
 *
 * @param id Additional split of the storage object by id. Can be used to separate users in store.
 * @example {2321: {view: "query"}, 7223: {view: "query"}}
 */
export function updateLocalStorageObject(
  key: string,
  updateObject: {},
  id?: number
) {
  try {
    const item = localStorage.getItem(key);

    // If there's no local storage item yet, create one
    if (!item) {
      updateLocalStorage(key, updateObject, id);
      return;
    }

    const storage = JSON.parse(item);

    if (typeof storage === "object" && typeof updateObject === "object") {
      if (id) {
        localStorage.setItem(
          key,
          JSON.stringify({
            ...{
              [id]: {
                ...updateObject,
                ...{ timeStamp: Date.now() },
              },
            },
          })
        );

        return;
      }

      localStorage.setItem(
        key,
        JSON.stringify({ ...storage, ...updateObject })
      );
    }
  } catch {
    console.error("Error updating local storage");
  }
}

/**
 *
 * @param key Local storage key
 * @param objKey Object key for data lookup
 * @param id Additional id for the lookup. For example, {2321: {view: "query"}, 7223: {view: "query"}}
 * @example {2321: {view: "query"}, 7223: {view: "query"}}
 */
export function getLocalStorageObject(
  key: string,
  objKey?: string,
  id?: number
) {
  try {
    const storage = localStorage.getItem(key);

    // Just return the falsy value and dont parse
    if (!storage) {
      return storage;
    }

    // If you need a nested object
    if (objKey) {
      const item = JSON.parse(storage);

      if (item && typeof item === "object") {
        if (id) {
          const objectId = item[id];

          if (objectId && typeof objectId === "object") {
            return objectId[objKey];
          }
        }

        return item[objKey];
      }
    }

    return JSON.parse(storage);
  } catch {
    console.error("Error reading local storage");
  }
}
