import { FC } from "react";

interface Props {
  step: number;
  stepAmount: number;
}

export const ProgressBar: FC<Props> = ({ step, stepAmount }) => {
  const PROGRESS_MAX_WIDTH = 160;
  const progress = (step / stepAmount) * PROGRESS_MAX_WIDTH;

  return (
    <div>
      <div style={{ marginBottom: 8, fontSize: 12 }}>
        Question: {step} / {stepAmount}
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            width: PROGRESS_MAX_WIDTH,
            height: 4,
            background: "#fff",
            borderRadius: 6,
            opacity: 0.2,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: -2,
            left: 0,
            width: progress,
            height: 8,
            background: "#fff",
            borderRadius: 6,
            transition: "width 0.2s linear",
          }}
        ></div>
      </div>
    </div>
  );
};
