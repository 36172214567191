import { FingerPrintView } from "../../../../features/views/FingerPrintView/FingerPrintView";
import { FingerprintForm } from "../../../../modules/fingerprint-steps/fingerprint-form/FingerprintForm";
import {
  VIDEO_MAPPING,
  VIDEO_MAPPING_WEBM,
  VIDEO_VALUEMAPPING_VTT,
} from "../../../../assets/media";

export function VideoValueMapping() {
  return (
    <FingerPrintView background="navy">
      <FingerprintForm
        name="Value Drivers Mapping"
        message="Thank you. Let's now explore how your value-drivers fit together."
        back="add-values"
        next="value-order"
        nextText="Go"
        navMessage="Please watch the video before advancing."
      >
        <div className="video-wrapper">
          <video autoPlay controls>
            <source src={VIDEO_MAPPING_WEBM.default} type="video/webm" />
            <source src={VIDEO_MAPPING.default} type="video/mp4" />

            <track
              src={VIDEO_VALUEMAPPING_VTT.default}
              label="English"
              kind="captions"
              srcLang="en-us"
              default={true}
            />

            <p>
              Your browser doesn't support HTML video. Here is a{" "}
              <a href={VIDEO_MAPPING.default} download="Value Mapping.mp4">
                link to the video
              </a>{" "}
              instead.
            </p>
          </video>
        </div>
      </FingerprintForm>
    </FingerPrintView>
  );
}
