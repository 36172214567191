import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useErrorHandler, useFormData } from "../../../../app/hooks";
import { allActions } from "../../../../app/store";
import { convertToString } from "./PotentialValues.fn";
import { FingerPrintView } from "../../../../features/views/FingerPrintView/FingerPrintView";
import { MessageBox } from "../../../../modules/helpers";
import { FingerprintForm } from "../../../../modules/fingerprint-steps/fingerprint-form/FingerprintForm";
import {
  getNarrativeData,
  saveRankedValuesArray,
  statusToVariant,
} from "../../../../services/user.service";
import { FingerprintContext } from "../../Fingerprint.context";
import { useSurveyResults } from "../Survey.hooks";
import { getValueDrivers } from "../Survey.fn";
import { AdditionalQuestions } from "../../../../components/AdditionalQuestions/AdditionalQuestions";
import SessionService from "../../../../services/session.service";

export function PotentialValues(props: any) {
  const dispatch = useDispatch();

  const { licenseCode, user } = SessionService.getInstance();

  const { surveyResults, surveyData } = useContext(FingerprintContext);

  const [isLoading, setIsLoading] = useState(true);

  const [narrativeData, setNarrativeData] = useState<any>({});

  const { getSurveyResults, isLoading: isLoadingResults } = useSurveyResults(
    user?.id || 0
  );

  const { addToForm } = useFormData();

  const [values, setValues] = useState<Array<string>>([""]);

  const { httpError } = useErrorHandler();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "video-value-drivers",
        "potential-values",
        "add-values"
      )
    );
  }, []);

  const handleChange = (e: any) => {
    let currentValues = [...values];

    if (currentValues.includes(e.target.value)) {
      currentValues.splice(currentValues.indexOf(e.target.value), 1);
    } else {
      currentValues = [...values, e.target.value];
    }

    setValues(currentValues.filter((element) => element != ""));

    addToForm(
      {
        name: e.target.name,
        value: convertToString(
          currentValues.filter((element) => element != "")
        ),
      },
      e.target.type
    );
  };

  useEffect(() => {
    loadValues();
  }, []);

  const loadValues = async () => {
    try {
      // Data is found in this session. Generate new results because user could have changed it
      if (surveyData.length) {
        const surveyResults = await getSurveyResults();

        const values = getValueDrivers(surveyResults);

        const dataToSave = {
          userId: user?.id,
          licenseCode,
          values,
          valuesExplanation: Object.entries(
            surveyResults?.value_drivers_explanation
          )?.map((e) => `${e[0]}: ${e[1]}`),
          archetype: surveyResults?.archetype,
          archetypeExplanation: surveyResults?.archetype_explanation,
          triggers: surveyResults?.triggers,
          triggersExplanation: Object.entries(
            surveyResults?.triggers_explanation
          )?.map((e) => `${e[0]}: ${e[1]}`),
        };

        // Save survey data already available at this point
        saveRankedValuesArray(dataToSave);

        setIsLoading(false);

        return;
      }

      const narrativeData = await getNarrativeData(licenseCode || "");

      // Data is already in the database
      if (narrativeData?.rankedValues) {
        setIsLoading(false);

        setNarrativeData(narrativeData);

        return;
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const valueDrivers = getValueDrivers(surveyResults);

  const isPending = isLoading || isLoadingResults;

  return (
    <FingerPrintView background="darkblue">
      {httpError.statusCode > 399 && (
        <MessageBox
          messages={httpError.messages}
          variant={statusToVariant(httpError.statusCode)}
        />
      )}

      <FingerprintForm
        name="Potential Values"
        message={
          isPending
            ? "We are now calculating Values for your review...."
            : "Here are a few potential values we pulled from your stories to get us started. Please take a moment to review the following list of values. As you watch the upcoming video, consider whether these values accurately reflect your most authentic self. Following the video, you will have the opportunity to adjust this list as needed."
        }
        back={isPending ? "" : "earliest-memory"}
        next={isPending ? "" : "video-value-drivers"}
        nextText="Next"
        navMessage="You will be able to edit these values and add more on the next step."
      >
        {!isPending && (
          <div style={{ marginTop: 35, marginBottom: 35 }}>
            <AdditionalQuestions />
          </div>
        )}

        {!isPending && (
          <fieldset>
            <ul className="values">
              {(narrativeData?.rankedValues?.split(",") || valueDrivers).map(
                (v: any, i: number) => {
                  return (
                    <Fragment key={v}>
                      <li key={i} className="value">
                        <input
                          name={props.name}
                          type="checkbox"
                          value={v}
                          onChange={handleChange}
                          checked={values.includes(v)}
                        />
                        <label>{v}</label>
                      </li>
                      <li key={v} className="explanation">
                        {narrativeData?.valuesExplanation
                          ?.split(";;")
                          ?.reduce((acc: any, curr: any) => {
                            const explanation = curr?.split(":");

                            return {
                              ...acc,
                              [explanation[0]]: explanation[1],
                            };
                          }, {})?.[v] ||
                          surveyResults?.value_drivers_explanation?.[v]}
                      </li>
                    </Fragment>
                  );
                }
              )}
            </ul>
          </fieldset>
        )}
      </FingerprintForm>
    </FingerPrintView>
  );
}
