import { useContext, useState } from "react";
import { FingerprintContext } from "../Fingerprint.context";
import { getValueDrivers } from "./Survey.fn";

export const useSurveyResults = (user_id: number) => {
  const [isLoading, setIsLoading] = useState(false);

  const { surveyData, setSurveyResults } = useContext(FingerprintContext);

  const getSurveyResults = () => {
    setIsLoading(true);

    return fetch(`${process.env.REACT_APP_AI_BASE_URL}/survey/result`, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        user_id,
        survey_data: surveyData.filter((d) => d?.answer),
      }),
    })
      .then(
        async (res) => {
          const result = await res.json();

          setSurveyResults(result);

          return result;
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => setIsLoading(false));
  };

  return { getSurveyResults, isLoading };
};

export const useValueOrder = (
  user_id: number,
  value_drivers: string[],
  survey_data: any[],
  is_completed: boolean
) => {
  const [isLoading, setIsLoading] = useState(false);

  const { setSurveyResultsFinal, setSelectedValuesByUser } =
    useContext(FingerprintContext);

  const getValueOrder = () => {
    setIsLoading(true);

    return fetch(
      `${process.env.REACT_APP_AI_BASE_URL}/survey/new_order_value_drivers`,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          user_id,
          value_drivers,
          survey_data: survey_data.filter((d) => d?.answer),
          is_completed,
        }),
      }
    )
      .then(
        async (res) => {
          const result = await res.json();

          setSurveyResultsFinal(result);

          const valueDrivers = getValueDrivers(result);
          setSelectedValuesByUser(valueDrivers);

          return result;
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => setIsLoading(false));
  };

  return { getValueOrder, isLoading };
};

export const useValuePhilosophy = (user_id: number) => {
  const [isLoading, setIsLoading] = useState(false);

  const { setValuePhilosophy } = useContext(FingerprintContext);

  const getValuePhilosophy = (
    value_drivers: any,
    value_drivers_explanation: any
  ) => {
    setIsLoading(true);

    return fetch(
      `${process.env.REACT_APP_AI_BASE_URL}/survey/values_philosophy`,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          user_id,
          value_drivers,
          value_drivers_explanation,
        }),
      }
    )
      .then(
        async (res) => {
          const result = await res.json();

          setValuePhilosophy(result?.values_philosophy);

          return result;
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => setIsLoading(false));
  };

  return { getValuePhilosophy, isLoading };
};
