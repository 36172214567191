import "./FingerprintMapping.scss";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import {
  FingerprintForm,
  RadioSelect,
} from "../fingerprint-form/FingerprintForm";
import { useFormData } from "../../../app/hooks";
import { useDispatch } from "react-redux";
import { allActions } from "../../../app/store";
import { useEffect, useState } from "react";

export function FingerprintMapping(props: any) {
  const dispatch = useDispatch();
  const { formData, addToForm } = useFormData();
  const [deleteValue, setDeleteValue] = useState<string>("");

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        props.back,
        props.current,
        props.next
      )
    );
  }, []);

  return (
    <FingerPrintView
      background="darkblue"
    >
      <FingerprintForm
        name={props.name}
        message={props.message}
        back={props.back}
        next={props.next}
        nextText="Next"
      >
        <RadioSelect
          name={props.name}
          options={[
            "When making a decision, I never betray this value. I would sacrifice anything to uphold it.",
            "This value is very important to me but I find myself betraying it every once in a while.",
            "Though I think it's important, I struggle to live up to this value.",
            "On further reflection, I do not value this.",
          ]}
          editForm={addToForm}
          formData={formData}
          questionType={"mapping"}
          value={props.message.split(": ")[1]}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}
