import { useDispatch } from "react-redux";
import { useFormData, useValueData } from "../../../../app/hooks";
import { useContext, useEffect, useState } from "react";
import { allActions } from "../../../../app/store";
import { FingerprintContext } from "../../Fingerprint.context";
import { FingerprintForm } from "../../../../modules/fingerprint-steps/fingerprint-form/FingerprintForm";
import { FingerPrintView } from "../../../../features/views/FingerPrintView/FingerPrintView";
import { ArrowIcon } from "../../../../features/icons/Icons";
import { AdditionalQuestions } from "../../../../components/AdditionalQuestions/AdditionalQuestions";
import SessionService from "../../../../services/session.service";
import { getNarrativeData } from "../../../../services/user.service";

export function AddValues(props: any) {
  const { addToForm } = useFormData();
  const { capitalizeString } = useValueData();
  const dispatch = useDispatch();

  const { licenseCode } = SessionService.getInstance();

  const [narrativeData, setNarrativeData] = useState<any>({});

  const [currentlyAddedValue, setCurrentlyAddedValue] = useState("");

  const [isAdding, setIsAdding] = useState(false);

  const {
    selectedValuesByUser,
    surveyResults,
    setSurveyResults,
    setSelectedValuesByUser,
    setSurveyData,
  } = useContext(FingerprintContext);

  const [initialValues, setInitialValues] =
    useState<string[]>(selectedValuesByUser);

  const isEmptyFieldError = selectedValuesByUser.some((v) => v === "");

  const isChangedValues =
    JSON.stringify(selectedValuesByUser) !== JSON.stringify(initialValues);

  const onEdit = (value: string, initial: string) => {
    const values = selectedValuesByUser.map((v) => (v === initial ? value : v));
    setSelectedValuesByUser(values);
  };

  const onDelete = (value: string) => {
    const deleted = selectedValuesByUser.find((v) => v === value);

    setSelectedValuesByUser(selectedValuesByUser.filter((v) => v !== deleted));
  };

  const sendToDispatch = (values: Array<string>) => {
    let valuesToDispatch = [];
    for (let i = 0; i < values.length; i++) {
      if (values[i] !== "") {
        values[i] = capitalizeString(values[i]);
        valuesToDispatch.push(values[i]);
      }
    }

    addToForm({ name: "add-values", value: valuesToDispatch.toString() });
  };

  const appendToValues = (newValue: string) => {
    let value = capitalizeString(newValue);
    dispatch(allActions.valueActions.appendValue(value));
  };

  const handleAdd = (event: any) => {
    event.preventDefault();
    if (currentlyAddedValue !== "") {
      setSelectedValuesByUser([...selectedValuesByUser, currentlyAddedValue]);
      sendToDispatch([...selectedValuesByUser, currentlyAddedValue]);
      appendToValues(currentlyAddedValue);
      setCurrentlyAddedValue("");
      setIsAdding(false);
    }
  };

  const includeAdding = () => {
    setIsAdding(true);
  };

  const excludeAdding = () => {
    setIsAdding(false);
  };

  const handleText = (event: any) => {
    setCurrentlyAddedValue(event.target.value);
  };

  const loadValues = async () => {
    try {
      const narrativeData = await getNarrativeData(licenseCode || "");

      // Continue with the database values
      if (narrativeData?.rankedValues && !selectedValuesByUser.length) {
        setNarrativeData(narrativeData);

        setSelectedValuesByUser(narrativeData?.rankedValues?.split(","));

        setSurveyData(narrativeData?.surveyData);

        setSurveyResults({
          archetype: narrativeData?.archetype,
          archetype_explanation: narrativeData?.archetypeExplanation,
          triggers: narrativeData?.triggers?.split(","),
          triggers_explanation: narrativeData?.triggersExplanation
            ?.split(";;")
            ?.reduce((acc: any, curr: any) => {
              const explanation = curr?.split(":");

              return {
                ...acc,
                [explanation[0]]: explanation[1],
              };
            }, {}),
          value_drivers: narrativeData?.rankedValues?.split(","),
          // value_drivers_explanation: narrativeData?.valuesExplanation
          //   ?.split(";;")
          //   ?.reduce((acc: any, curr: any) => {
          //     const explanation = curr?.split(":");

          //     return {
          //       ...acc,
          //       [explanation[0]]: explanation[1],
          //     };
          //   }, {}),
        });

        setInitialValues(narrativeData?.rankedValues?.split(","));

        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadValues();
  }, []);

  const valueDriverExplanation =
    surveyResults?.value_drivers_explanation ||
    narrativeData?.valuesExplanation
      ?.split(";;")
      ?.reduce((acc: any, curr: any) => {
        const explanation = curr?.split(":");

        return {
          ...acc,
          [explanation[0]]: explanation[1],
        };
      }, {});

  const ALLOWED_CHARACTERS = /[^A-Za-z -]/g;

  const containsForbiddenChar = selectedValuesByUser.some((v) =>
    v.match(ALLOWED_CHARACTERS)
  );

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Add Values"
        message={
          "Now that you understand the difference between a value and a value-driver, please reflect on each value in the list. Consider whether each one is something you would sacrifice for and if it truly represents you at your best. You can delete values that don't resonate with you or add new ones that better reflect who you are and how you make decisions."
        }
        back={"video-value-drivers"}
        next={"video-values-mapping"}
        nextText="Next"
        navMessage={
          selectedValuesByUser.length < 5
            ? "Please, add at least 5 value-drivers"
            : "You may have up to 10 values."
        }
        disabledNext={selectedValuesByUser.length < 5 || containsForbiddenChar}
      >
        <div style={{ marginTop: 35, marginBottom: 35 }}>
          <AdditionalQuestions />
        </div>

        {isChangedValues && (
          <div style={{ marginBottom: 35, fontSize: "1.5rem" }}>
            <span
              className="back-button"
              onClick={() => setSelectedValuesByUser(initialValues)}
            >
              Restore
            </span>{" "}
            my initial values.
          </div>
        )}

        <ul className="values">
          {selectedValuesByUser?.map((v, i) => (
            <div key={i} style={{ position: "relative" }}>
              {isEmptyFieldError && v === "" && (
                <div
                  style={{
                    position: "absolute",
                    top: 16,
                    left: -32,
                    color: "darkorange",
                    fontSize: 24,
                    cursor: "default",
                  }}
                  title="Please, use meaningfull and non-empty values"
                >
                  &#9888;
                </div>
              )}

              <input
                id={`${v}-${i}`}
                disabled={isEmptyFieldError && v !== ""}
                style={{
                  width: "100%",
                  height: "6rem",
                  marginBottom: 10,
                  paddingLeft: 20,
                  paddingRight: 125,
                  border: "solid 2px white",
                  background: "transparent",
                  color: "#fff",
                }}
                type="text"
                value={v}
                onChange={({ target: { value } }) => {
                  onEdit(value, v);
                }}
              />

              {v.match(ALLOWED_CHARACTERS) && (
                <div style={{ marginBottom: 15, color: "lightcoral" }}>
                  &#9888; Please, don't use numbers and special characters in
                  your values
                </div>
              )}

              {valueDriverExplanation && (
                <div
                  id={`${v}-${i}-explainer`}
                  style={{ display: "none", marginBottom: 20 }}
                >
                  {valueDriverExplanation[v]}
                </div>
              )}

              <div
                className="edit-panel"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  display: "flex",
                  fontSize: "30px",
                }}
              >
                <div
                  style={{ padding: "14px 8px", cursor: "pointer" }}
                  title="Edit"
                  onClick={() => document.getElementById(`${v}-${i}`)?.focus()}
                >
                  &#9998;
                </div>

                {valueDriverExplanation && (
                  <div
                    style={{ padding: "14px 8px", cursor: "pointer" }}
                    title={valueDriverExplanation[v]}
                    onClick={() => {
                      let elem = document.getElementById(`${v}-${i}-explainer`);

                      if (elem) {
                        const display = elem.style.display;

                        elem.style.display =
                          display === "block" ? "none" : "block";
                      }
                    }}
                  >
                    &#8505;
                  </div>
                )}

                <div
                  style={{ padding: "14px 8px", cursor: "pointer" }}
                  title="Delete"
                  onClick={() => onDelete(v)}
                >
                  &#128465;
                </div>
              </div>
            </div>
          ))}
        </ul>

        {isAdding && (
          <div className="adding-a-value">
            <input
              type="text"
              className="adding-value"
              placeholder="type here…"
              onChange={handleText}
              value={currentlyAddedValue}
              autoFocus
            />
            <span className="plus-close" onClick={excludeAdding}>
              ✕
            </span>
          </div>
        )}

        <div className="add-value-button-wrap">
          {/* {!isAdding && liAdded.length + listPV.length <= 9 && ( */}
          {!isAdding && selectedValuesByUser.length <= 9 && (
            <button className="add" onClick={includeAdding}>
              <span className="plus">+</span>
              <span className="btn-text">Add another value</span>
            </button>
          )}

          {isAdding && (
            <button className="add" onClick={handleAdd}>
              <span className="plus">
                <ArrowIcon color="#F7F5F2" />
              </span>
              <span className="btn-text">Add this value</span>
            </button>
          )}

          {!isAdding && selectedValuesByUser.length > 9 && (
            <p style={{ fontSize: "1.5rem" }}>
              You have 10 value-drivers and cannot add any more.
            </p>
          )}
        </div>
      </FingerprintForm>
    </FingerPrintView>
  );
}
